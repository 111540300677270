import { React, useState, useEffect, useContext } from 'react';
import { GlobalContext } from '../Context/GlobalContext';
import axios from 'axios';
import {
  Box,
  Text,
  Input,
  VStack,
  Image,
  Link,
  Stack,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Button,
  Heading,
  Menu,
  MenuButton,
  InputRightElement,
  InputGroup,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Wrap,
  WrapItem,
  Tooltip,
  IconButton,
} from '@chakra-ui/react';
import {
  ChevronDownIcon,
  DeleteIcon,
  ViewIcon,
  ViewOffIcon,
} from '@chakra-ui/icons';

export const InputNormal = ({
  value,
  placeholder,
  setVar,
  children,
  required,
}) => {
  const { editCase } = useContext(GlobalContext);
  return (
    <Box w='100%'>
      <FormControl isRequired={required} id='Name'>
        <FormLabel fontWeight='bold'>{children}</FormLabel>
        <Input
          value={value}
          placeholder={placeholder}
          _placeholder={editCase ? { color: 'black' } : { color: 'gray.500' }}
          type='text'
          onChange={event => {
            setVar(event.target.value);
          }}
        />
      </FormControl>
    </Box>
  );
};

export const InputDate = ({ value, placeholder, setVar, children }) => {
  return (
    <Box w='100%'>
      <FormControl id='Date'>
        <FormLabel fontWeight='bold'>{children}</FormLabel>
        <Input
          value={value}
          type='date'
          placeholder={placeholder}
          onChange={event => {
            setVar(event.target.value);
          }}
        />
      </FormControl>
    </Box>
  );
};

export const InputTime = ({ value, setVar, children }) => {
  return (
    <Box w='100%'>
      <FormControl id='Time'>
        <FormLabel fontWeight='bold'>{children}</FormLabel>
        <Input
          value={value}
          type='time'
          onChange={event => {
            setVar(event.target.value);
          }}
        />
      </FormControl>
    </Box>
  );
};

export const InputPhoto = ({ value, field, setVar, children }) => {
  const [imageName, setImageName] = useState('');

  async function handlePhoto(event) {
    event.preventDefault();
    const file = event.target.files[0];
    console.log(file);
    const response = await uploadImage(file, field);
    // setImageName(response.originalName);
    setVar(response);
  }

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <VStack align='start' w='100%' gap='10px'>
      <Text fontWeight='bold'>{children} :</Text>
      <Box
        // w='100%'
        h='40px'
        pt='6px'
        px='5px'
        bg={'#f59120'}
        color={'white'}
        _hover={{
          bg: '#db831f',
        }}
        borderRadius='0.25rem'
        position='relative'
        textAlign='center'
      >
        Select Image
        <Input
          type='file'
          accept='image/*'
          capture='environment'
          height='100%'
          width='100%'
          position='absolute'
          top='0'
          left='0'
          opacity='0'
          borderRadius='0.25rem'
          onChange={handlePhoto}
          // name='image'
        />
      </Box>
      {value != '' && (
        <Box w='200px'  mt={4}>
          <Image
            onClick={onOpen}
            boxSize='200px'
            mx='5px'
            p='5px'
            border='1px solid #858585'
            objectFit={'cover'}
            src={
              `/api/image/getImage/` +
              `${localStorage.getItem('ngoId')}` +
              '/' +
              value
            }
            alt='Preview'
          />
          {/* <Tooltip label='Delete'>
            <IconButton
              color='red'
              bg=''
              fontSize='20px'
              _hover={{ color: '#dc3545' }}
              icon={<DeleteIcon />}
              onClick={() =>
                handleDeleteImg(value, localStorage.getItem('ngoId'))
              }
            />
          </Tooltip> */}
          <Modal size='xl' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>File Name : {value}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack w='100%'>
                  <Image
                    src={
                      `/api/image/getImage/` +
                      `${localStorage.getItem('ngoId')}` +
                      '/' +
                      value
                    }
                    alt='Preview'
                  />
                </VStack>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme='blue' mr={3} onClick={onClose}>
                  Close
                </Button>
                <Button
                  onClick={() => {
                    handleDownload(value);
                  }}
                  variant='ghost'
                >
                  Download
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      )}
    </VStack>
  );
};

async function uploadImage(file, field) {
  const formData = new FormData();
  formData.append('fieldName', field);
  formData.append('ngoId', localStorage.getItem('ngoId'));
  formData.append('image', file, file.name);
  console.log(localStorage.getItem('ngoId'))
  console.log('formdata : ', formData);
  const response = await axios.post('/image/createImage', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  if (response.status === 200) {
    console.log(response.data);
    // setImageName(response.data.data.originalname);
    // setVar(response.data.data.filename);
    return response.data.data;
  } else console.error(response.data);
}

function handleDownload(filename) {
  console.log(
    `/api/image/getImage/` + `${localStorage.getItem('ngoId')}` + '/' + filename
  );
  fetch(
    `/api/image/getImage/` + `${localStorage.getItem('ngoId')}` + '/' + filename
  ).then(response => {
    response.blob().then(blob => {
      const fileURL = window.URL.createObjectURL(blob);
      let alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = `${filename}`;
      alink.click();
    });
  });
}

async function handleDeleteImg(image, ngoId, value, setVar) {
  var data = {
    ngoId: ngoId,
    image: image,
  };
  const response = await axios.post('/image/deleteImage', data);
  if (response.status === 200) {
    console.log(response.data);
    await setVar(value.filter(val => val !== image));
  } else console.error(response.data);
}

export const InputPhotos = ({ value, field, setVar, children }) => {
  const imageUrls = [];

  async function handlePhotos(event) {
    const files = event.target.files;
    const images = Array.from(files);
    console.log(images);
    for (const img of images) {
      const response = await uploadImage(img, field);
      imageUrls.push(response);
      console.log('exiting img array creation');
    }
    // setVar(imageUrls);
    setVar(value.concat(imageUrls));
    console.log('exiting upload pics');
  }

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <VStack w='100%' align='start' spacing='10px'>
      <Text fontWeight='bold'>{children} -</Text>
      <Box
        // w='100%'
        h='40px'
        pt='6px'
        px='5px'
        bg={'#f59120'}
        color={'white'}
        _hover={{
          bg: '#db831f',
        }}
        borderRadius='0.25rem'
        position='relative'
        textAlign='center'
      >
        Select Images
        <Input
          type='file'
          accept='image/*'
          multiple={true}
          capture='environment'
          onChange={handlePhotos}
          height='100%'
          width='100%'
          position='absolute'
          top='0'
          left='0'
          opacity='0'
          borderRadius='0.25rem'
        />
      </Box>
      <Wrap>
        {value?.map(image => (
          <WrapItem>
            <VStack w='200px' mt={4}>
              <Image
                onClick={onOpen}
                boxSize='200px'
                mx='5px'
                p='5px'
                border='1px solid #858585'
                objectFit={'cover'}
                src={
                  `/api/image/getImage/` +
                  `${localStorage.getItem('ngoId')}` +
                  '/' +
                  image
                }
                alt='Preview'
              />
              <Tooltip label='Delete'>
                <IconButton
                  color='red'
                  bg=''
                  fontSize='20px'
                  _hover={{ color: '#dc3545' }}
                  icon={<DeleteIcon />}
                  onClick={() =>
                    handleDeleteImg(
                      image,
                      localStorage.getItem('ngoId'),
                      value,
                      setVar
                    )
                  }
                />
              </Tooltip>
              <Modal size='xl' isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>File Name : {image}</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <VStack w='100%'>
                      <Image
                        src={
                          `/api/image/getImage/` +
                          `${localStorage.getItem('ngoId')}` +
                          '/' +
                          image
                        }
                        alt='Preview'
                      />
                    </VStack>
                  </ModalBody>

                  <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                      Close
                    </Button>
                    <Button
                      onClick={() => {
                        handleDownload(image);
                      }}
                      variant='ghost'
                    >
                      Download
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </VStack>
          </WrapItem>
        ))}
      </Wrap>
    </VStack>
  );
};

export const InputMenu = ({ children, options, val, setVal, required }) => {
  return (
    <Box w='100%'>
      <FormControl isRequired={required} id='Menu'>
        <FormLabel fontWeight='bold'>{children}</FormLabel>
        <Menu matchWidth={true}>
          <MenuButton
            w='100%'
            bg='white'
            border='1px solid #ced4da'
            borderRadius='0.4rem'
            textAlign='start'
            as={Button}
            rightIcon={<ChevronDownIcon />}
          >
            {val}
          </MenuButton>
          <MenuList>
            {options.map(opt => (
              <MenuItem onClick={e => setVal(opt)}>{opt}</MenuItem>
            ))}
          </MenuList>
        </Menu>
      </FormControl>
    </Box>
  );
};

export const InputPassword = ({ placeholder, setVar, children, required }) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <FormControl isRequired={required} w='100%' id='password'>
      <FormLabel>{children}</FormLabel>
      <InputGroup>
        <Input
          placeholder={placeholder}
          type={showPassword ? 'text' : 'password'}
          onChange={event => {
            setVar(event.target.value);
          }}
        />
        <InputRightElement h={'full'}>
          <Button
            variant={'ghost'}
            onClick={() => setShowPassword(showPassword => !showPassword)}
          >
            {showPassword ? <ViewIcon /> : <ViewOffIcon />}
          </Button>
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
};
