import React from 'react';
import axios from 'axios';
import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ViewIcon, ViewOffIcon, EmailIcon } from '@chakra-ui/icons';
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  FormErrorMessage,
  useColorModeValue,
  Box,
  InputGroup,
  InputRightElement,
  Image,
  useToast,
} from '@chakra-ui/react';

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const [failedLogin, setFailedLogin] = useState(false);

  const navigate = useNavigate();
  const toast = useToast();
  const loginIdEmpty = loginId.trim() === '';
  const passwordEmpty = password.trim() === '';
  const passwordMinLength = password.trim().length < 6;
  const handleLogin = async () => {
    if (loginIdEmpty || passwordEmpty || passwordMinLength) {
      setFailedLogin(true);
      return;
    }
    const payload = { loginId, password };
    console.log(payload);
    try {
      const res = await axios.post('/auth/login', JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const status = res.status;
      if (status === 200) {
        console.log(res.data);
        localStorage.setItem('role', res.data.data.role);
        localStorage.setItem('userId', res.data.data.id);
        localStorage.setItem('userNgo', res.data.data.ngoId);
        toast({
          title: 'Login successful',
          description: 'Getting your dashboard ready.',
          status: 'success',
          duration: 800,
          isClosable: true,
        });
        setTimeout(() => {
          navigate('/dashboard');
        }, 800);
      }
    } catch (e) {
      console.log(e.response);
      if (e.response) {
        const res = e.response;
        if (res.status === 422) {
          console.log('A');
          console.error('Authentication failed');
          toast({
            title: 'Login failed.',
            description: res.data.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        } else if (res.status === 401) {
          console.log('B');
          console.error('Authentication failed');
          toast({
            title: 'Login failed.',
            description: 'Incorrect login ID or password.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        console.error('Error', e);
        console.log('C');

        toast({
          title: 'Service unavailable.',
          description: 'We will be back up shortly.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bgGradient='linear(45deg,#328f8a,#f59120)'
    >
      <Stack spacing={12} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Box
          minW='400px'
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Stack spacing={8} align={'center'}>
            <Image src={'/logo.png'} w={150}></Image>
            <Heading fontSize={'4xl'}>Strayscue</Heading>

            <FormControl isInvalid={loginIdEmpty && failedLogin}>
              <InputGroup>
                <Input
                  type='text'
                  placeholder='Login ID'
                  value={loginId}
                  onChange={e => {
                    setLoginId(e.target.value.trim());
                  }}
                />
                <InputRightElement h={'full'}>
                  <EmailIcon />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>Login ID is required</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={(passwordEmpty || passwordMinLength) && failedLogin}
            >
              <InputGroup>
                <Input
                  placeholder='Password'
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={e => {
                    setPassword(e.target.value.trim());
                  }}
                />
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    onClick={() =>
                      setShowPassword(showPassword => !showPassword)
                    }
                  >
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {passwordEmpty ? (
                <FormErrorMessage>Password is required</FormErrorMessage>
              ) : (
                passwordMinLength && (
                  <FormErrorMessage>
                    Password minimum length is 6
                  </FormErrorMessage>
                )
              )}
            </FormControl>

            <Stack
              direction={{ base: 'row' }}
              justify={'space-between'}
              w={'100%'}
              align={'start'}
              spacing={10}
            >
              <Stack
                direction={{ base: 'column' }}
                align={'start'}
                justify={'space-between'}
              >
                {/* <Checkbox>Remember me</Checkbox> */}
                <Button
                  as={Link}
                  color={'blue.400'}
                  onClick={() => {
                    toast({
                      title: 'Contact your administrator.',
                      status: 'warning',
                      duration: 800,
                      isClosable: true,
                    });
                  }}
                >
                  Forgot password?
                </Button>
              </Stack>
              <Button
                bg={'#f59120'}
                color={'white'}
                _hover={{
                  bg: '#db831f',
                }}
                type='submit'
                onClick={handleLogin}
              >
                Sign in
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
