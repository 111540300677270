export const CASE_STATUS = [
  'Reported',
  'Admitted',
  'Blood Test',
  'Operation',
  'Post Operation',
  'Released',
];
export const CASE_TYPE = ['Sterilization', 'OPD', 'IPD', 'Vaccination'];
export const CASE_MORTALITY = ['Healthy', 'Unhealthy', 'Fatal'];
