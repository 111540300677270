import React, { useState } from 'react';

import {
  Box,
  Text,
  Input,
  Link,
  Image,
  Stack,
  FormControl,
  FormLabel,
  InputGroup,
  VStack,
  HStack,
  InputRightElement,
  Button,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import pp from '../Assets/img/pp.png';
import { InputPassword, InputPhoto } from '../Components/Inputs';

export default function Settings() {
  return (
    <VStack w={'100%'}>
      <ProfileSetting />
      <ChangePassword />
    </VStack>
  );
}

function ProfileSetting() {
  return (
    <VStack w='100%' px='20px' pb='50px'>
      <HStack justify={'space-between'} w={'100%'} align={'center'}>
        <Text py='19px' fontWeight='semibold' fontSize='1.8rem'>
          Profile Setting
        </Text>
        <Text py='19px' fontWeight='semibold' fontSize='1.2rem'>
          <Link color={'blue.400'}>Settings</Link> / Profile Setting
        </Text>
      </HStack>
      <Box
        w={{ base: '100%', md: '60%' }}
        display='flex'
        justifyContent='center'
      >
        <Stack
          w='100%'
          // maxW={1100}
          py={50}
          px={{ base: '10px', sm: '50px' }}
          bg='white'
          border='0 solid rgba(0,0,0,.125)'
          borderRadius='0.25rem'
          boxShadow='0 0 1px rgb(0 0 0 / 13%), 0 1px 3px #0003'
          spacing={8}
          align={'start'}
        >
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            alignItems='center'
            w='100%'
            gap='20px'
          >
            <VStack w={{ base: '100%', lg: '50%' }} alignItems='start'>
              <Text fontWeight='bold'>User Name</Text>
              <Input placeholder='Master Admin' />
            </VStack>
            <HStack w={{ base: '100%', lg: '50%' }} gap='20px'>
              <Image
                src={pp}
                boxSize={{ base: '80px', sm: '100px', lg: '150px' }}
                border='2px solid #404700cc'
                borderRadius='50%'
              />
              <InputPhoto>Profile Picture</InputPhoto>
            </HStack>
          </Stack>
          <Button
            bg={'#6c757d'}
            color={'white'}
            _hover={{
              bg: '#5c636a',
            }}
            borderRadius='0.25rem'
            w='100px'
          >
            Submit
          </Button>
        </Stack>
      </Box>
    </VStack>
  );
}

function ChangePassword() {
  return (
    <VStack w='100%' px='20px' pb='50px'>
      <HStack justify={'space-between'} w={'100%'} align={'center'}>
        <Text py='19px' fontWeight='semibold' fontSize='1.8rem'>
          Change Password
        </Text>
        <Text py='19px' fontWeight='semibold' fontSize='1.2rem'>
          <Link color={'blue.400'}>Settings</Link> / Change Password
        </Text>
      </HStack>
      <Box
        w={{ base: '100%', md: '60%' }}
        display='flex'
        justifyContent='center'
      >
        <Stack
          w='100%'
          py={50}
          px={{ base: '5px', md: '100px' }}
          bg='white'
          border='0 solid rgba(0,0,0,.125)'
          borderRadius='0.25rem'
          boxShadow='0 0 1px rgb(0 0 0 / 13%), 0 1px 3px #0003'
          spacing={8}
          align={'center'}
        >
          <InputPassword>Old Password</InputPassword>
          <InputPassword>New Password</InputPassword>
          <InputPassword>Confirm New Password</InputPassword>
          <Button
            bg={'#6c757d'}
            color={'white'}
            _hover={{
              bg: '#5c636a',
            }}
            borderRadius='0.25rem'
            width={'100%'}
            maxW='300px'
          >
            Submit
          </Button>
        </Stack>
      </Box>
    </VStack>
  );
}
