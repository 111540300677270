import { React, useState, useEffect, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Text,
  Input,
  Link,
  Stack,
  Flex,
  FormControl,
  FormLabel,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  InputGroup,
  HStack,
  InputRightElement,
  Button,
  Heading,
  useMultiStyleConfig,
  useColorModeValue,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { GlobalContext } from '../Context/GlobalContext';
import { InputNormal, InputPhoto, InputDate, InputMenu } from '../Components/Inputs';

export default function AddSponsor() {
  const {
    editSponsor,
    //sponsor
    sponsorName,
    setSponsorName,
    sponsorType,
    setSponsorType,
    sponsorAmt,
    setSponsorAmt,
    sponsorLogo,
    setSponsorLogo,
    sponsorStartDate,
    setSponsorStartDate,
    sponsorEndDate,
    setSponsorEndDate,
  } = useContext(GlobalContext);

  const typeOpts = [
    'Vaccination',
    'Sterilization',
    'OPD',
    'IPD',
    'Adoption',
    'Post Op Care',
    'Other',
  ];

  const [userRole, setUserRole] = useState(localStorage.getItem('role'));
  const [ngolist, setngolist] = useState([]);
  const [ngoName, setNgoName] = useState('');

  const getNgos = async () => {
    try {
      const res = await axios.post('/ngo/getNgos', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const status = res.status;
      if (status === 200) {
        console.log(res.data);
        setngolist(res.data.data.map(a => a.name));
      }
    } catch (e) {
      console.log(e.response);
    }
  };

  const getNgoById = async () => {
    var data = { ngoId: localStorage.getItem('ngoId') };
    const response = await axios.post('/ngo/getNgoById', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status == 200) {
      const ngodetails = response.data.data;
      setNgoName(ngodetails.name);
    } else {
      console.error(response.data);
    }
  };

  const getSponsorById = async () => {
    const payload = { id: localStorage.getItem('sponsorId') };
    console.log(payload);
    try {
      const res = await axios.post(
        '/sponsor/getSponsorById',
        JSON.stringify(payload),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const status = res.status;
      if (status === 200) {
        console.log(res.data);
        setSponsorName(res.data.data.name);
        setSponsorType(res.data.data.type);
        setSponsorAmt(res.data.data.amount);
        setSponsorLogo(res.data.data.logo);
        setSponsorStartDate(res.data.data.startDate)
        setSponsorEndDate(res.data.data.endDate)
      }
    } catch (e) {
      console.log(e.response);
    }
  };

  useEffect(() => {
    // console.log('Edit User: ' + editUser);
    // console.log('Sending request with id: ' + viewUserId);
    getNgos();
    if (localStorage.getItem('editUser')) {
      getSponsorById();
      getNgoById();
    } 
  }, []);

  const navigate = useNavigate();
  const toast = useToast();
  const handleAddSponsor = async () => {
    const url = editSponsor ? '/sponsor/updateSponsor' : '/sponsor/createSponsor';
    if (sponsorName == '' || sponsorType == '' || sponsorAmt == '') {
      toast({
        title: 'Error',
        description: 'Please fill Required Details',
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    } else {
      var data = {
        name: sponsorName,
        type: sponsorType,
        amount: sponsorAmt,
        logo: sponsorLogo,
        startDate: sponsorStartDate,
        endDate: sponsorEndDate,
      };
      if (localStorage.getItem('role') === 'Admin') data.ngoName = ngoName;
      for (let prop in data) {
        if (
          data[`${prop}`] === '' ||
          data[`${prop}`] === null ||
          (Array.isArray(data[`${prop}`]) && data[`${prop}`].length === 0)
        ) {
          delete data[`${prop}`];
          console.log('removing prop : ', prop);
        }
      }
      console.log('edited data : ', data);
      try {
        const res = await axios.post(url, JSON.stringify(data), {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const status = res.status;
        if (status === 200) {
          console.log(res.data);
          toast({
            title: 'Done',
            description: 'Sponsor created successfully',
            status: 'success',
            duration: 800,
            isClosable: true,
          });
          setTimeout(() => {
            navigate('/sponsor');
          }, 800);
        }
      } catch (e) {
        console.log(e);
        toast({
          title: 'Error',
          description: e.response.data.message,
          status: 'error',
          duration: 10000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Box w='100%' px={'15px'} pb='20px'>
      <Stack
        justify={'space-between'}
        w={'100%'}
        align={'center'}
        direction={'row'}
      >
        <Text
          py='19px'
          fontWeight='semibold'
          fontSize={{ base: '1.3rem', sm: '1.8rem' }}
        >
          Add Sponsor
        </Text>
        <Text
          py='19px'
          fontWeight='semibold'
          fontSize={{ base: '1rem', sm: '1.2rem' }}
        >
          <Link color={'blue.400'}>Dashboard</Link> / Add Sponsor
        </Text>
      </Stack>

      <VStack
        w='100%'
        bg='white'
        p='30px'
        border='0 solid rgba(0,0,0,.125)'
        borderRadius='0.25rem'
        boxShadow='0 0 1px rgb(0 0 0 / 13%), 0 1px 3px #0003'
        spacing={{ base: '2', md: '10' }}
      >
        <Heading
          w='100%'
          textAlign='start'
          fontSize={{ base: '2xl', md: '4xl' }}
        >
          Sponsor Details :
        </Heading>

        <Stack
          w='100%'
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '2', md: '10' }}
        >
          <InputNormal
            value={sponsorName}
            setVar={setSponsorName}
            placeholder={'Name'}
            required={true}
          >
            Sponsor Name
          </InputNormal>
          <InputMenu
            val={sponsorType}
            setVal={setSponsorType}
            options={typeOpts}
          >
            Animal Fit for Surgery
          </InputMenu>
          <InputNormal
            value={sponsorAmt}
            setVar={setSponsorAmt}
            placeholder={'Amount'}
            required={true}
          >
            Sponsor Amount
          </InputNormal>
        </Stack>
        <Stack
          w='100%'
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '2', md: '10' }}
        >
          <InputDate
            value={sponsorStartDate}
            placeholder={'Start Date'}
            setVar={setSponsorStartDate}
          >
            Start Date
          </InputDate>
          <InputDate
            value={sponsorEndDate}
            placeholder={'End Date'}
            setVar={setSponsorEndDate}
          >
            End Date
          </InputDate>
        </Stack>
        <InputPhoto value={sponsorLogo} setVar={setSponsorLogo} field={'Logo'}>
          Sponsor Logo
        </InputPhoto>
        {userRole == 'Admin' && (
          <InputMenu
            options={ngolist}
            val={ngoName}
            setVal={setNgoName}
            // required={editUser ? false : true}
          >
            NGO
          </InputMenu>
        )}

        <Box
          w='100%'
          mt='30px'
          h='100px'
          display='flex'
          alignItems='center'
          justifyContent='center'
          borderTop='1px solid #d8d6d4'
        >
          <Button
            h='40px'
            bg='#f59120'
            color='white'
            borderRadius='0.25rem'
            fontSize='15px'
            _hover={{}}
            onClick={handleAddSponsor}
          >
            Save
          </Button>
        </Box>
      </VStack>
    </Box>
  );
}
