import { React, useState, useEffect, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';
import {
  Box,
  Text,
  Input,
  Link,
  theme,
  IconButton,
  Icon,
  Button,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Tabs,
  TabList,
  Tab,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import {
  AddIcon,
  DeleteIcon,
  ChevronDownIcon,
  EditIcon,
} from '@chakra-ui/icons';
import { InputMenu } from '../Components/Inputs';
import { GlobalContext } from '../Context/GlobalContext';
import {
  CASE_MORTALITY,
  CASE_STATUS,
  CASE_TYPE,
} from '../Components/constants';

export default function Dashboard() {
  const [caseData, setCaseData] = useState([]);
  const [totalCases, setTotalCases] = useState(0);
  const [reportedCases, setReportedCases] = useState(0);
  const [admittedCases, setAdmittedCases] = useState(0);
  const [releasedCases, setReleasedCases] = useState(0);
  const [caseStatus, setCaseStatus] = useState('');
  const info = [
    {
      number: totalCases,
      text: 'Total Cases',
    },
    {
      number: reportedCases,
      text: 'Reported',
    },
    {
      number: admittedCases,
      text: 'Admitted',
    },
    {
      number: releasedCases,
      text: 'Released',
    },
  ];

  const tableHeadings = [
    'Action',
    'Status',
    'Reporter Name',
    'Location',
    'Landmark',
    'Pincode',
    'Created By',
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    mortality,
    setMortality,
    type,
    setType,
    status,
    setStatus,
    causeOfFailure,
    setCauseOfFailure,
    caseID,
    setCaseID,
    editCase,
    setEditCase,
    dashboardTab,
    setDashboardTab,
    setReporterName,
    setReporterContact,
    setReporterAltContact,
    setReporterEmail,
    setLocation,
    setLandmark,
    setPincode,
    setReportedDate,
    setReportedTime,
    setPickupDate,
    setPickupTime,
    setReporterLastMod,
    setConsentFormImage,
    setVerificationIdFrontImage,
    setVerificationIdBackImage,
    //animal
    setAnimalId,
    setAnimalSpecies,
    setAnimalBreed,
    setAnimalAge,
    setAnimalTemperament,
    setAnimalGender,
    setAnimalPregnant,
    setAnimalMarking,
    setAnimalColor,
    setAnimalCatchable,
    setAnimalWeight,
    setAdmissionReason,
    setAnimalPictures,
    //medical
    setMedicalHistory,
    setVaccinationStatus,
    setDewormed,
    setFitForSurgery,
    setOtherDetails,
    setAdmissionDate,
    setFeedingRecordImage,
    setBloodReportImage,
    //operation
    setVetName,
    setOperationDate,
    setOperationStartTime,
    setOperationEndTime,
    setOperationOutcome,
    setMedicalPrescriptionImage,
    setTreatmentRecordImage,
    setOrganImage,
    //pop
    setPopComment,
    setPopFacility,
    setPopExpectedDays,
    setPopStartDate,
    setPopEndDate,
    setReleaseDate,
    setComments,
    setPopPictures,
    setReleasePictures,
    setEuthanized,
    setPopLocation,
  } = useContext(GlobalContext);

  const navigate = useNavigate();

  const handleAddCase = async () => {
    const payload = {
      type,
      status,
      mortality,
    };
    console.log(payload);

    try {
      const res = await axios.post(
        '/case/createCase',
        JSON.stringify(payload),
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (res.status === 200) {
        console.log(res.data);
        // localStorage.setItem('editCase', false);
        setEditCase(false);
        localStorage.setItem('caseId', res.data.data.id);
        localStorage.setItem('ngoId', res.data.data.ngoId);
        setCaseID(res.data.data.id);
        navigate('/dashboard/case');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const tabs = ['All', 'Sterilization', 'OPD', 'IPD', 'Vaccination'];

  useEffect(() => {
    console.log(dashboardTab);
    getCaseByType();
  }, [dashboardTab]);

  useEffect(() => {
    localStorage.removeItem('caseId');
    localStorage.removeItem('ngoId');
    localStorage.removeItem('editUser');
    localStorage.removeItem('editNgo');
    localStorage.removeItem('viewUserId');
    localStorage.removeItem('editCase');
    console.log('changed edit status : ', localStorage.getItem('editCase'));
    getCaseByType();
    resetContext();
  }, []);

  const toast = useToast();
  const getCaseByType = async () => {
    const payload = { type: dashboardTab };
    console.log(payload);
    try {
      const res = await axios.post(
        '/case/getCaseByType',
        JSON.stringify(payload),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const status = res.status;
      if (status === 200) {
        console.log(res.data);
        setCaseData(res.data.data);
      }
    } catch (e) {
      console.log(e.response);
    }
  };

  useEffect(() => {
    setTotalCases(caseData.length);
    setReportedCases(caseData.filter(obj => obj.status === 'Reported').length);
    setAdmittedCases(caseData.filter(obj => obj.status === 'Admitted').length);
    setReleasedCases(caseData.filter(obj => obj.status === 'Released').length);
  }, [caseData]);

  const DeleteCaseButton = props => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const handleDeleteCase = async () => {
      const { caseId } = props;
      console.log(`Deleting case ${caseId}`);
      const res = await axios.post(
        '/case/deleteCase',
        { caseId },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log(res.data);
      if (res.status === 200) {
        getCaseByType();
      }
    };
    return (
      <Box>
        <Tooltip label='Delete'>
          <IconButton
            color='red'
            bg=''
            fontSize='20px'
            _hover={{ color: '#dc3545' }}
            icon={<DeleteIcon />}
            onClick={onOpen}
          />
        </Tooltip>
        <Modal
          closeOnOverlayClick={true}
          isOpen={isOpen}
          onClose={onClose}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirm delete case</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              Are you sure you want to delete the case?
            </ModalBody>
            <ModalFooter>
              <Button
                type='submit'
                onClick={handleDeleteCase}
                bg='red'
                color='white'
                mr={3}
              >
                Delete
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    );
  };

  const resetContext = async () => {
    console.log('Resetting Context');
    setMortality('Healthy');
    setType('Choose');
    setStatus('Reported');
    setCauseOfFailure('');
    //reporting
    setReporterName('');
    setReporterContact('');
    setReporterAltContact('');
    setReporterEmail('');
    setLocation('');
    setLandmark('');
    setPincode('');
    setReportedDate(dayjs().format('YYYY-MM-DD'));
    setReportedTime(dayjs().format('hh:mm'));
    setPickupDate(dayjs().format('YYYY-MM-DD'));
    setPickupTime(dayjs().format('hh:mm'));
    setConsentFormImage([]);
    setVerificationIdFrontImage('');
    setVerificationIdBackImage('');
    //animal
    setAnimalId('');
    setAnimalSpecies('Dog');
    setAnimalBreed('Indie');
    setAnimalAge('1-5 Yrs');
    setAnimalTemperament('Scared');
    setAnimalGender('Female');
    setAnimalPregnant('No');
    setAnimalMarking('');
    setAnimalColor('');
    setAnimalCatchable('Yes');
    setAnimalWeight('');
    setAdmissionReason('');
    setAnimalPictures([]);
    //medical
    setMedicalHistory('');
    setVaccinationStatus('Already Done');
    setDewormed('Already Done');
    setFitForSurgery('Yes');
    setOtherDetails('');
    setAdmissionDate(dayjs().format('YYYY-MM-DD'));
    setFeedingRecordImage([]);
    setBloodReportImage([]);
    //op
    setVetName('');
    setOperationDate(dayjs().format('YYYY-MM-DD'));
    setOperationStartTime(dayjs().format('hh:mm'));
    setOperationEndTime(dayjs().format('hh:mm'));
    setOperationOutcome('Success');
    setMedicalPrescriptionImage([]);
    setTreatmentRecordImage([]);
    setOrganImage([]);
    //pop
    setPopComment('');
    setPopFacility('In Shelter');
    setPopExpectedDays('');
    setPopStartDate(dayjs().format('YYYY-MM-DD'));
    setPopEndDate(dayjs().format('YYYY-MM-DD'));
    setReleaseDate(dayjs().format('YYYY-MM-DD'));
    setComments('');
    setPopPictures([]);
    setReleasePictures([]);
    setEuthanized('No');
    setPopLocation('');
  };

  const handleUpdateStatus = async (
    caseId,
    status,
    type,
    mortality,
    failure
  ) => {
    var data = {
      caseId: caseId,
      status: status,
      type: type,
      mortality: mortality,
      causeOfFailure: failure === null ? 'Cause of Failure' : failure,
    };
    console.log(data);
    const response = await axios.post('/case/updateCase', data);
    if (response.status === 200) {
      console.log(response.data);
      getCaseByType();
    } else {
      toast({
        title: 'Error',
        description: 'Status Update Unsuccessfull',
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    }
  };

  return (
    <Box w='100%' px='20px' pb='20px'>
      <Text py='19px' fontWeight='semibold' fontSize='1.8rem'>
        Dashboard
      </Text>
      <Box
        w='100%'
        display='flex'
        flexFlow={{ base: 'row wrap', lg: 'nowrap' }}
        justifyContent='space-between'
        // bg='aqua'
      >
        {info.map(e => (
          <StatBox info={e} />
        ))}
      </Box>

      <Box
        w='100%'
        h='950px'
        bg='white'
        border='0 solid rgba(0,0,0,.125)'
        borderRadius='0.25rem'
        boxShadow='0 0 1px rgb(0 0 0 / 13%), 0 1px 3px #0003'
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        <Text
          w='100%'
          h='60px'
          px='25px'
          pt='10px'
          borderBottom='1px solid #d8d6d4'
          fontWeight='semibold'
          fontSize='25px'
        >
          Case Lists
        </Text>
        <Box
          w='100%'
          p='30px'
          mb='20px'
          gap={{ base: '20%', md: '50%' }}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Button
            minW='120px'
            h='40px'
            bg='#f59120'
            color='white'
            borderRadius='0.25rem'
            fontSize='15px'
            leftIcon={<AddIcon />}
            _hover={{}}
            onClick={onOpen}
          >
            Add case
          </Button>
          <Modal
            closeOnOverlayClick={true}
            isOpen={isOpen}
            onClose={onClose}
            isCentered
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add New Case</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <InputMenu options={CASE_TYPE} val={type} setVal={setType}>
                  Type of case
                </InputMenu>
                <InputMenu
                  options={CASE_STATUS}
                  val={status}
                  setVal={setStatus}
                >
                  Status of Case
                </InputMenu>
                <InputMenu
                  options={CASE_MORTALITY}
                  val={mortality}
                  setVal={setMortality}
                >
                  Mortality of Case
                </InputMenu>
              </ModalBody>
              <ModalFooter>
                {/* <Link as={NavLink} to='/dashboard/case' _hover={{}}> */}
                <Button
                  type='submit'
                  onClick={handleAddCase}
                  bg='#f59120'
                  color='white'
                  mr={3}
                >
                  Next
                </Button>
                {/* </Link> */}
                <Button onClick={onClose}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Input
            size='md'
            borderRadius='0.25rem'
            placeholder='Search by location, status etc'
          />
        </Box>
        <Box w='calc(100% - 60px)' bg='' minH='640px' overflow='auto'>
          <TableContainer>
            <Tabs
              // index={tabIndex}
              // onChange={handleTabsChange}
              // onChange={() => {console.log('kk')}}
              align='start'
              w='100%'
              variant='unstyled'
              mb='10px'
            >
              <TabList w='100%'>
                {tabs.map(tl => (
                  <TabL tabname={tl}>{tl}</TabL>
                ))}
              </TabList>
            </Tabs>
            <Table variant='striped'>
              <Thead>
                <Tr>
                  {tableHeadings.map(th => (
                    <ThN>{th}</ThN>
                  ))}
                </Tr>
              </Thead>

              <Tbody>
                {caseData.map(data => (
                  <Tr>
                    <Td fontSize='16px' p='0px'>
                      <Box
                        w='100%'
                        h='60px'
                        display='flex'
                        px='10px'
                        gap=''
                        justifyContent='center'
                        alignItems='center'
                        borderLeft='1px solid #d8d6d4'
                        borderBottom='1px solid #d8d6d4'
                      >
                        <Tooltip label='Edit'>
                          <Link as={NavLink} to='/dashboard/case' _hover={{}}>
                            <Button
                              size='sm'
                              bg='#f59120'
                              color='white'
                              _hover={{ bg: '#a85b1b ' }}
                              borderRadius='3px'
                              onClick={() => {
                                setEditCase(true);
                                setCaseID(data.id);
                                localStorage.setItem('editCase', true);
                                localStorage.setItem('caseId', data.id);
                                localStorage.setItem('ngoId', data.ngoId);
                              }}
                            >
                              Edit Case
                            </Button>
                          </Link>
                        </Tooltip>
                        <DeleteCaseButton caseId={data.id} />
                      </Box>
                    </Td>
                    <Td fontSize='16px' p='0px'>
                      <Box
                        w='100%'
                        h='60px'
                        display='flex'
                        px=''
                        gap=''
                        justifyContent='center'
                        alignItems='center'
                        borderLeft='1px solid #d8d6d4'
                        borderBottom='1px solid #d8d6d4'
                      >
                        <Menu>
                          <MenuButton
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                          >
                            {data.status}
                          </MenuButton>
                          <MenuList>
                            {CASE_STATUS.map(status => (
                              <MenuItem
                                onClick={() => {
                                  setCaseStatus(status);
                                  handleUpdateStatus(
                                    data.id,
                                    status,
                                    data.type,
                                    data.mortality,
                                    data.causeOfFailure
                                  );
                                }}
                              >
                                {status}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </Menu>
                        {/* {data.status} */}
                      </Box>
                    </Td>
                    <Td fontSize='16px' p='0px'>
                      <Box
                        w='100%'
                        h='60px'
                        display='flex'
                        px=''
                        gap=''
                        justifyContent='center'
                        alignItems='center'
                        borderLeft='1px solid #d8d6d4'
                        borderBottom='1px solid #d8d6d4'
                      >
                        {data.reportingDetail.reporterName}
                      </Box>
                    </Td>
                    <Td fontSize='16px' p='0px'>
                      <Box
                        w='100%'
                        h='60px'
                        display='flex'
                        px=''
                        gap=''
                        justifyContent='center'
                        alignItems='center'
                        borderLeft='1px solid #d8d6d4'
                        borderBottom='1px solid #d8d6d4'
                      >
                        {data.reportingDetail.location}
                      </Box>
                    </Td>
                    <Td fontSize='16px' p='0px'>
                      <Box
                        w='100%'
                        h='60px'
                        display='flex'
                        px='5px'
                        gap=''
                        justifyContent='center'
                        alignItems='center'
                        borderLeft='1px solid #d8d6d4'
                        borderBottom='1px solid #d8d6d4'
                      >
                        {data.reportingDetail.landmark}
                      </Box>
                    </Td>
                    <Td fontSize='16px' p='0px'>
                      <Box
                        w='100%'
                        h='60px'
                        display='flex'
                        px=''
                        gap=''
                        justifyContent='center'
                        alignItems='center'
                        borderLeft='1px solid #d8d6d4'
                        borderBottom='1px solid #d8d6d4'
                      >
                        {data.reportingDetail.pincode}
                      </Box>
                    </Td>
                    <Td fontSize='16px' p='0px'>
                      <Box
                        w='100%'
                        h='60px'
                        display='flex'
                        px=''
                        gap=''
                        justifyContent='center'
                        alignItems='center'
                        borderLeft='1px solid #d8d6d4'
                        borderBottom='1px solid #d8d6d4'
                      >
                        <Text>User</Text>
                        {/* {localStorage.getItem('role') === 'Admin' ? data.ngo.name : data.user.name} */}
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
}

const ThN = ({ children }) => {
  return (
    <Th border='1px solid #d8d6d4' color='black'>
      <Text textAlign='center' fontSize='16px'>
        {children}
      </Text>
    </Th>
  );
};

const TabL = ({ tabname, children }) => {
  const { dashboardTab, setDashboardTab } = useContext(GlobalContext);
  return (
    <Tab
      fontWeight='bold'
      w='100%'
      minW={{ base: '80px', md: '120px' }}
      fontSize={{ base: '13px', sm: '12px', md: '13px', lg: '16px' }}
      px={{ base: '0px', sm: '10px', md: '10px', lg: '16px' }}
      border='1px solid #6C757D'
      borderRight={children.slice(0, 1) == 'V' ? '1px solid #6C757D' : '0'}
      color='black'
      _selected={{ color: 'white', bg: '#6C757D' }}
      onClick={() => {
        setDashboardTab(tabname);
      }}
    >
      {children}
    </Tab>
  );
};

function StatBox(props) {
  return (
    <Box
      className='group'
      w={{ base: '48%', lg: '24%' }}
      mb='25px'
      h='140px'
      boxShadow='0 0 1px rgb(0 0 0 / 13%), 0 1px 3px #0003'
      borderRadius='1.25rem'
      bg='white'
      _hover={{ color: 'white', bg: '#f59120' }}
      px='25px'
      display='flex'
      alignItems='center'
      justifyContent='space-between'
    >
      <Box display='flex' flexDirection='column'>
        <Text
          fontSize='35px'
          _groupHover={{ fontSize: '40px' }}
          transitionDuration='.3s'
          transitionTimingFunction='ease-in-out'
          fontWeight='semibold'
        >
          {props.info.number}
        </Text>
        <Text
          fontSize='16px'
          _groupHover={{ fontSize: '20px' }}
          transitionDuration='.3s'
          transitionTimingFunction='ease-in-out'
        >
          {props.info.text}
        </Text>
      </Box>
      {/* <Icon
        boxSize='81'
        _groupHover={{ boxSize: '90px', color: 'white' }}
        color='#00000026'
        as={props.info.icon}
        transitionDuration='.3s'
        transitionTimingFunction='ease-in-out'
      /> */}
    </Box>
  );
}
