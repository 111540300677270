import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Text,
  Input,
  Link,
  Stack,
  Image,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  InputRightElement,
  Button,
  useColorModeValue,
  VStack,
  useToast,
} from '@chakra-ui/react';
import {
  InputMenu,
  InputNormal,
  InputPassword,
  InputPhoto,
  InputPhotos,
} from '../Components/Inputs';
import pp from '../Assets/img/pp.png';
import { GlobalContext } from '../Context/GlobalContext';

export const authRoleMap = {
  Admin: ['Admin', 'Founder', 'Manager', 'Worker'],
  Founder: ['Founder', 'Manager', 'Worker'],
  Manager: ['Worker'],
};

export default function AddUser() {
  const {
    viewUserId,
    setViewUserId,
    editUser,
    setEditUser,
    name,
    setName,
    email,
    setEmail,
    username,
    setUsername,
    password,
    setPassword,
    contact,
    setContact,
    profileImage,
    setProfileImage,
    verificationId,
    setVerificationId,
    role,
    setRole,
    userDetails,
    setUserDetails,
  } = useContext(GlobalContext);

  const [userRole, setUserRole] = useState(localStorage.getItem('role'));
  const [ngolist, setngolist] = useState([]);
  const [ngoName, setNgoName] = useState('');

  const getNgos = async () => {
    try {
      const res = await axios.post('/ngo/getNgos', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const status = res.status;
      if (status === 200) {
        console.log(res.data);
        setngolist(res.data.data.map(a => a.name));
      }
    } catch (e) {
      console.log(e.response);
    }
  };

  const getUserById = async () => {
    const payload = { id: localStorage.getItem('viewUserId') };
    console.log(payload);
    try {
      const res = await axios.post(
        '/user/getUserById',
        JSON.stringify(payload),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const status = res.status;
      if (status === 200) {
        console.log(res.data);
        setName(res.data.data.name);
        setEmail(res.data.data.email);
        setUsername(res.data.data.username);
        setContact(res.data.data.contact);
        setProfileImage(res.data.data.profileImage);
        setVerificationId(res.data.data.verificationId);
        setRole(res.data.data.role);
      }
    } catch (e) {
      console.log(e.response);
    }
  };

  const clearContext = async () => {
    setName('');
    setEmail('');
    setUsername('');
    setPassword('');
    setContact('');
    setProfileImage('');
    setVerificationId([]);
    setRole('');
  };

  useEffect(() => {
    console.log('Edit User: ' + editUser);
    console.log('Sending request with id: ' + viewUserId);
    getNgos();
    if (localStorage.getItem('editUser')) {
      getUserById();
      getNgoById();
    } else clearContext();
  }, []);

  // useEffect(() => {
  //   // setEditUser(localStorage.getItem('editUser'));
  //   if (editUser || localStorage.getItem('editUser')) {
  //     getUserById();
  //     getNgoById();
  //   } else clearContext();
  // }, [editUser]);

  const getNgoById = async () => {
    var data = { ngoId: localStorage.getItem('ngoId') };
    const response = await axios.post('/ngo/getNgoById', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status == 200) {
      const ngodetails = response.data.data;
      setNgoName(ngodetails.name);
    } else {
      console.error(response.data);
    }
  };

  const toast = useToast();
  const navigate = useNavigate();
  const handleAddUser = () => {
    if (
      name === '' ||
      email === '' ||
      username === '' ||
      contact === '' ||
      role === '' ||
      (!editUser && password == '')
    ) {
      toast({
        title: 'Error',
        description: 'Please fill Required Details',
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    } else {
      var data = {
        name: name,
        email: email,
        username: username,
        contact: contact,
        password: password,
        role: role,
        // ngoName: ngoName
      };
      if (localStorage.getItem('role') === 'Admin') data.ngoName = ngoName;
      console.log(data);
      // if(password != '') data.password = password;
      if (editUser) {
        data.id = viewUserId;
        data.profileImage = profileImage;
        data.verificationId = verificationId;
      }
      for (let prop in data) {
        if (
          data[`${prop}`] === '' ||
          data[`${prop}`] === null ||
          (Array.isArray(data[`${prop}`]) && data[`${prop}`].length === 0)
        ) {
          delete data[`${prop}`];
        }
      }
      console.log('edited data : ', data);

      const url = editUser ? '/user/updateUser' : '/user/createUser';
      var config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: url,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(data),
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          navigate('/user');
        })
        .catch(function (error) {
          console.log(error);
          toast({
            title: 'Error',
            description: error.response.data.message,
            status: 'error',
            duration: 10000,
            isClosable: true,
          });
        });
    }
  };

  return (
    <Box w='100%' px={'15px'} pb='20px'>
      <Stack
        justify={'space-between'}
        w={'100%'}
        align={'center'}
        direction={'row'}
      >
        <Text
          py='19px'
          fontWeight='semibold'
          fontSize={{ base: '1.3rem', sm: '1.8rem' }}
        >
          {localStorage.getItem('editUser') ? 'Edit User' : 'Add User'}
        </Text>
        <Text
          py='19px'
          fontWeight='semibold'
          fontSize={{ base: '1rem', sm: '1.2rem' }}
        >
          <Link color={'blue.400'}>Dashboard</Link> /{' '}
          {localStorage.getItem('editUser') ? 'Edit User' : 'Add User'}
        </Text>
      </Stack>
      <Box
        w='100%'
        // minH='650px'
        bg='white'
        border='0 solid rgba(0,0,0,.125)'
        borderRadius='0.25rem'
        boxShadow='0 0 1px rgb(0 0 0 / 13%), 0 1px 3px #0003'
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='space-evenly'
        gap='20px'
        pt={{ base: '15px', md: '30px' }}
      >
        <Stack
          w='100%'
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '2', md: '10' }}
          alignItems='center'
          justifyContent='space-evenly'
          // bg='red'
        >
          <VStack
            w={{ base: '90%', md: '60%' }}
            spacing={{ base: '2', md: '10' }}
          >
            <Stack
              w='100%'
              direction={{ base: 'column', md: 'row' }}
              spacing={{ base: '2', md: '10' }}
            >
              <InputNormal
                value={name}
                setVar={setName}
                placeholder={'Name'}
                required={editUser ? false : true}
              >
                Name
              </InputNormal>
              <InputNormal
                value={email}
                setVar={setEmail}
                placeholder={'Email'}
                required={editUser ? false : true}
              >
                Email
              </InputNormal>
            </Stack>
            <Stack
              w='100%'
              direction={{ base: 'column', md: 'row' }}
              spacing={{ base: '2', md: '10' }}
            >
              <InputNormal
                value={username}
                setVar={setUsername}
                placeholder={'Username'}
                required={editUser ? false : true}
              >
                UserName
              </InputNormal>
              <InputPassword
                value={password}
                setVar={setPassword}
                placeholder={'Password'}
                required={editUser ? false : true}
              >
                Password
              </InputPassword>
            </Stack>
            <Stack
              w='100%'
              direction={{ base: 'column', md: 'row' }}
              spacing={{ base: '2', md: '10' }}
            >
              <InputNormal
                value={contact}
                setVar={setContact}
                placeholder={'Contact'}
                required={editUser ? false : true}
              >
                Contact
              </InputNormal>
              <InputMenu
                val={role}
                setVal={setRole}
                options={authRoleMap[localStorage.getItem('role')]}
                required={editUser ? false : true}
              >
                Type
              </InputMenu>
            </Stack>
            {userRole == 'Admin' && (
              <InputMenu
                options={ngolist}
                val={ngoName}
                setVal={setNgoName}
                required={editUser ? false : true}
              >
                NGO
              </InputMenu>
            )}
          </VStack>
          {localStorage.getItem('editUser') && (
            <VStack w={{ base: '90%', md: '30%' }} align='start' spacing='20px'>
              <HStack align='start' spacing='20px'>
                <InputPhoto
                  value={profileImage}
                  setVar={setProfileImage}
                  field={'Profile'}
                >
                  Profile Image
                </InputPhoto>
                <Image
                  src={
                    `/api/image/getImage/` +
                    `${localStorage.getItem('ngoId')}` +
                    '/' +
                    profileImage
                  }
                  boxSize={{ base: '80px', sm: '100px', lg: '150px' }}
                  border='2px solid #404700cc'
                  borderRadius='50%'
                />
              </HStack>
              <HStack align='start' spacing='20px'>
                <InputPhotos
                  value={verificationId}
                  setVar={setVerificationId}
                  field={'Verification'}
                >
                  Verification ID
                </InputPhotos>
                {/* <Image
                src={pp}
                boxSize={{ base: '80px', sm: '100px', lg: '150px' }}
                border='2px solid #404700cc'
                borderRadius='50%'
              /> */}
              </HStack>
            </VStack>
          )}
        </Stack>
        <Box
          w='100%'
          mt='30px'
          h='100px'
          display='flex'
          alignItems='center'
          justifyContent='center'
          borderTop='1px solid #d8d6d4'
        >
          <Button
            h='40px'
            bg='#f59120'
            color='white'
            borderRadius='0.25rem'
            fontSize='15px'
            _hover={{}}
            onClick={handleAddUser}
          >
            {editUser ? 'Save' : 'Add'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
