import { React, useState, useEffect, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Text,
  Input,
  Link,
  Stack,
  Flex,
  FormControl,
  FormLabel,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  InputGroup,
  HStack,
  InputRightElement,
  Button,
  Heading,
  useMultiStyleConfig,
  useColorModeValue,
  VStack,
  useToast,
} from '@chakra-ui/react';
import ReportingDetails from '../Components/Reporting';
import AnimalDetails from '../Components/Animal';
import MedicalDetails from '../Components/Medical';
import OperationDetails from '../Components/Operation';
import PostOpDetails from '../Components/PostOp';
import { InputMenu, InputNormal } from '../Components/Inputs';
import { GlobalContext } from '../Context/GlobalContext';
// import { AssignCaseDetails, AssignReportingDetails } from '../Context/HandleContext';

import {
  CASE_MORTALITY,
  CASE_STATUS,
  CASE_TYPE,
} from '../Components/constants';

export default function AddCase() {
  const Tablists = [
    'Reporter Details',
    'Animal Details',
    'Medical Details',
    'Operation Details',
    'Post Operation Details',
  ];

  const {
    caseID,
    setCaseID,
    editCase,
    setEditCase,
    tabIndex,
    setTabIndex,
    //case
    setMortality,
    setType,
    setStatus,
    setCauseOfFailure,
    //reporting
    setReporterName,
    setReporterContact,
    setReporterAltContact,
    setReporterEmail,
    setLocation,
    setLandmark,
    setPincode,
    setReportedDate,
    setReportedTime,
    setPickupDate,
    setPickupTime,
    setReporterLastMod,
    setConsentFormImage,
    setVerificationIdFrontImage,
    setVerificationIdBackImage,
    //animal
    animalId,
    setAnimalId,
    setAnimalSpecies,
    setAnimalBreed,
    setAnimalAge,
    setAnimalTemperament,
    setAnimalGender,
    setAnimalPregnant,
    setAnimalMarking,
    setAnimalColor,
    setAnimalCatchable,
    setAnimalWeight,
    setAdmissionReason,
    setAnimalPictures,
    //medical
    setMedicalHistory,
    setVaccinationStatus,
    setDewormed,
    setFitForSurgery,
    setOtherDetails,
    setAdmissionDate,
    setFeedingRecordImage,
    setBloodReportImage,
    //operation
    setVetName,
    setOperationDate,
    setOperationStartTime,
    setOperationEndTime,
    setOperationOutcome,
    setMedicalPrescriptionImage,
    setTreatmentRecordImage,
    setOrganImage,
    //pop
    setPopComment,
    setPopFacility,
    setPopExpectedDays,
    setPopStartDate,
    setPopEndDate,
    setReleaseDate,
    setComments,
    setPopPictures,
    setReleasePictures,
    setEuthanized,
    setPopLocation,
  } = useContext(GlobalContext);

  const handleTabsChange = index => {
    setTabIndex(index);
  };

  function isValid(value) {
    if (
      value === '' ||
      value === null ||
      (Array.isArray(value) && value.length === 0)
    )
      return false;
    else return true;
  }

  const getCaseById = async () => {
    console.log(
      'in get case by id',
      localStorage.getItem('editCase'),
      '  ||| ',
      editCase
    );
    const payload = { id: localStorage.getItem('caseId') };
    console.log(payload);
    try {
      const res = await axios.post(
        '/case/getCaseById',
        JSON.stringify(payload),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const status = res.status;
      if (status === 200) {
        console.log(res.data);
        const response = res.data.data;
        const respReporting = res.data.data.reportingDetail;
        const respAnimal = response.animalDetail;
        const respMedical = response.medicalDetail;
        const respOp = response.operationDetail;
        const respPop = response.postOperationDetail;
        setType(response.type);
        setMortality(response.mortality);
        setStatus(response.status);
        isValid(response.causeOfFailure) &&
          setCauseOfFailure(response.causeOfFailure);
        isValid(respReporting.reporterName) &&
          setReporterName(respReporting.reporterName);
        isValid(respReporting.reporterContact) &&
          setReporterContact(respReporting.reporterContact);
        isValid(respReporting.reporterAltContact) &&
          setReporterAltContact(respReporting.reporterAltContact);
        isValid(respReporting.reporterEmail) &&
          setReporterEmail(respReporting.reporterEmail);
        isValid(respReporting.location) && setLocation(respReporting.location);
        isValid(respReporting.landmark) && setLandmark(respReporting.landmark);
        isValid(respReporting.pincode) && setPincode(respReporting.pincode);
        isValid(respReporting.reportedDate) &&
          setReportedDate(respReporting.reportedDate);
        isValid(respReporting.reportedTime) &&
          setReportedTime(respReporting.reportedTime);
        isValid(respReporting.pickupDate) &&
          setPickupDate(respReporting.pickupDate);
        isValid(respReporting.pickupTime) &&
          setPickupTime(respReporting.pickupTime);
        isValid(respReporting.consentFormImage) &&
          setConsentFormImage(respReporting.consentFormImage);
        isValid(respReporting.verificationIdBackImage) &&
          setVerificationIdBackImage(respReporting.verificationIdBackImage);
        isValid(respReporting.verificationIdFrontImage) &&
          setVerificationIdFrontImage(respReporting.verificationIdFrontImage);
        //animal
        isValid(respAnimal.uniqueID) && setAnimalId(respAnimal.uniqueID);
        isValid(respAnimal.animalSpecies) &&
          setAnimalSpecies(respAnimal.animalSpecies);
        isValid(respAnimal.animalBreed) &&
          setAnimalBreed(respAnimal.animalBreed);
        isValid(respAnimal.animalAge) && setAnimalAge(respAnimal.animalAge);
        isValid(respAnimal.animalTemperament) &&
          setAnimalTemperament(respAnimal.animalTemperament);
        isValid(respAnimal.animalGender) &&
          setAnimalGender(respAnimal.animalGender);
        isValid(respAnimal.animalPregnant) &&
          setAnimalPregnant(respAnimal.animalPregnant);
        isValid(respAnimal.animalMarking) &&
          setAnimalMarking(respAnimal.animalMarking);
        isValid(respAnimal.animalColor) &&
          setAnimalColor(respAnimal.animalColor);
        isValid(respAnimal.animalCatchable) &&
          setAnimalCatchable(respAnimal.animalCatchable);
        isValid(respAnimal.animalWeight) &&
          setAnimalWeight(respAnimal.animalWeight);
        isValid(respAnimal.admissionReason) &&
          setAdmissionReason(respAnimal.admissionReason);
        isValid(respAnimal.animalPictures) &&
          setAnimalPictures(respAnimal.animalPictures);
        //medical
        isValid(respMedical.medicalHistory) &&
          setMedicalHistory(respMedical.medicalHistory);
        isValid(respMedical.vaccinationStatus) &&
          setVaccinationStatus(respMedical.vaccinationStatus);
        isValid(respMedical.dewormed) && setDewormed(respMedical.dewormed);
        isValid(respMedical.fitForSurgery) &&
          setFitForSurgery(respMedical.fitForSurgery);
        isValid(respMedical.otherDetails) &&
          setOtherDetails(respMedical.otherDetails);
        isValid(respMedical.admissionDate) &&
          setAdmissionDate(respMedical.admissionDate);
        isValid(respMedical.feedingRecordImage) &&
          setFeedingRecordImage(respMedical.feedingRecordImage);
        isValid(respMedical.bloodReportImage) &&
          setBloodReportImage(respMedical.bloodReportImage);
        //operation
        isValid(respOp.vetName) && setVetName(respOp.vetName);
        isValid(respOp.operationDate) && setOperationDate(respOp.operationDate);
        isValid(respOp.operationStartTime) &&
          setOperationStartTime(respOp.operationStartTime);
        isValid(respOp.operationEndTime) &&
          setOperationEndTime(respOp.operationEndTime);
        isValid(respOp.operationOutcome) &&
          setOperationOutcome(respOp.operationOutcome);
        isValid(respOp.medicalPrescriptionImage) &&
          setMedicalPrescriptionImage(respOp.medicalPrescriptionImage);
        isValid(respOp.treatmentRecordImage) &&
          setTreatmentRecordImage(respOp.treatmentRecordImage);
        isValid(respOp.organImage) && setOrganImage(respOp.organImage);
        //pop
        isValid(respPop.popComment) && setPopComment(respPop.popComment);
        isValid(respPop.popFacility) && setPopFacility(respPop.popFacility);
        isValid(respPop.popExpectedDays) &&
          setPopExpectedDays(respPop.popExpectedDays);
        isValid(respPop.popStartDate) && setPopStartDate(respPop.popStartDate);
        isValid(respPop.popEndDate) && setPopEndDate(respPop.popEndDate);
        isValid(respPop.releaseDate) && setReleaseDate(respPop.releaseDate);
        isValid(respPop.comments) && setComments(respPop.comments);
        isValid(respPop.popPictures) && setPopPictures(respPop.popPictures);
        isValid(respPop.releasePictures) &&
          setReleasePictures(respPop.releasePictures);
        isValid(respPop.euthanized) && setEuthanized(respPop.euthanized);
        isValid(respPop.popLocation) && setPopLocation(respPop.popLocation);
      }
      console.log('done setting variabkes');
    } catch (e) {
      console.log(e.response);
    }
  };

  useEffect(() => {
    // setEditCase(localStorage.getItem('editCase'));
    setTabIndex(0);
    console.log('Edit Case: ' + editCase);
    console.log('local : ', localStorage.getItem('editCase'));
    console.log('Sending request with id: ' + localStorage.getItem('caseId'));
    getCaseById();
  }, []);

  // useEffect(() => {
  //   console.log('in useeffect edit case');
  //   // setEditCase(localStorage.getItem('editCase'));
  //   if (localStorage.getItem('editCase')) {
  //     console.log('in if');
  //     console.log('Edit Case: ' + editCase);
  //     console.log('local : ', localStorage.getItem('editCase'));
  //     getCaseById();
  //   }
  // }, [editCase]);

  return (
    <Box w='100%' px={'15px'} pb='20px'>
      <Stack
        justify={'space-between'}
        w={'100%'}
        align={'center'}
        direction={'row'}
      >
        <Text
          py='19px'
          fontWeight='semibold'
          fontSize={{ base: '1.3rem', sm: '1.8rem' }}
        >
          {localStorage.getItem('editCase') ? 'Edit Case' : 'Add Case'}
          {/* Case Details */}
        </Text>
        <Text
          py='19px'
          fontWeight='semibold'
          fontSize={{ base: '1rem', sm: '1.2rem' }}
        >
          <Link as={NavLink} to='/dashboard' color={'blue.400'}>
            Dashboard
          </Link>{' '}
          / {localStorage.getItem('editCase') ? 'Edit Case' : 'Add Case'}
          {/* / Case Details */}
        </Text>
      </Stack>
      <VStack>
        <CaseEditor />
        <Box
          w='100%'
          // minH='650px'
          bg='white'
          border='0 solid rgba(0,0,0,.125)'
          borderRadius='0.25rem'
          boxShadow='0 0 1px rgb(0 0 0 / 13%), 0 1px 3px #0003'
          display='flex'
          flexDirection='column'
          alignItems='center'
          pt={{ base: '15px', md: '30px' }}
          px='5px'
        >
          <Tabs
            index={tabIndex}
            onChange={handleTabsChange}
            align='center'
            w='100%'
            variant='unstyled'
          >
            <TabList w='100%'>
              {Tablists.map(tl => (
                <TabL>{tl}</TabL>
              ))}
            </TabList>

            <TabPanels w='100%'>
              <TabPanel p='10px'>
                <ReportingDetails />
              </TabPanel>
              <TabPanel p='10px'>
                <AnimalDetails />
              </TabPanel>
              <TabPanel p='10px'>
                <MedicalDetails />
              </TabPanel>
              <TabPanel p='10px'>
                <OperationDetails />
              </TabPanel>
              <TabPanel p='10px'>
                <PostOpDetails />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </VStack>
    </Box>
  );
}

const TabL = ({ children }) => {
  return (
    <Tab
      w='100%'
      fontWeight='bold'
      fontSize={{ base: '10px', sm: '12px', md: '13px', lg: '16px' }}
      px={{ base: '0px', sm: '10px', md: '10px', lg: '16px' }}
      border='1px solid #6C757D'
      borderRight={children.slice(0, 1) == 'P' ? '1px solid #6C757D' : '0'}
      color='black'
      _selected={{ color: 'white', bg: '#6C757D' }}
    >
      {children}
    </Tab>
  );
};

const CaseEditor = props => {
  const {
    editCase,
    setEditCase,
    mortality,
    setMortality,
    type,
    setType,
    status,
    setStatus,
    causeOfFailure,
    setCauseOfFailure,
    caseDetails,
    setCaseDetails,
  } = useContext(GlobalContext);

  const navigate = useNavigate();
  const UpdateCase = async () => {
    const payload = {
      type,
      status,
      mortality,
      causeOfFailure,
      caseId: localStorage.getItem('caseId'),
    };
    console.log(payload);

    try {
      const res = await axios.post(
        '/case/updateCase',
        JSON.stringify(payload),
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log(res.data);
    } catch (e) {
      console.error(e);
    }
  };

  const toast = useToast();
  const handleSave = () => {
    if (type === 'Choose') {
      toast({
        title: 'Error',
        description: 'Please choose Case Type',
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    } else {
      UpdateCase();
    }
  };

  const handleSaveExit = () => {
    if (type == 'Choose') {
      toast({
        title: 'Error',
        description: 'Please choose Case Type',
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    } else {
      UpdateCase();
      navigate('/dashboard');
    }
  };

  return (
    <VStack
      w={'100%'}
      bg={'white'}
      border='0 solid rgba(0,0,0,.125)'
      borderRadius='0.25rem'
      boxShadow='0 0 1px rgb(0 0 0 / 13%), 0 1px 3px #0003'
      px={5}
      py={{ base: 15, md: 30 }}
      spacing={3}
    >
      <Stack
        direction={{ base: 'column', md: 'row' }}
        w={'100%'}
        alignItems='center'
        spacing={{ base: '2', md: '10' }}
      >
        <InputMenu val={type} setVal={setType} options={CASE_TYPE}>
          Type of case
        </InputMenu>
        <InputMenu val={status} setVal={setStatus} options={CASE_STATUS}>
          Status of Case
        </InputMenu>
        <InputMenu
          val={mortality}
          setVal={setMortality}
          options={CASE_MORTALITY}
        >
          Mortality of Case
        </InputMenu>
      </Stack>
      <Stack
        w={'100%'}
        spacing={5}
        direction={{ base: 'column', md: 'row' }}
        alignItems='end'
        justifyContent={'space-between'}
      >
        <InputNormal
          value={causeOfFailure}
          placeholder={'Cause of Failure'}
          setVar={setCauseOfFailure}
        >
          Cause of Failure
        </InputNormal>
        <HStack
          w={{ base: '100%', md: '25%' }}
          spacing={3}
          justifyContent={'space-between'}
        >
          <Button
            bg='#17a2b8'
            size={{ base: 'sm', md: 'md' }}
            borderRadius='0.25rem'
            color='white'
            _hover={{ bg: '#268594' }}
            flexGrow={1}
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            bg='#17a2b8'
            size={{ base: 'sm', md: 'md' }}
            borderRadius='0.25rem'
            color='white'
            _hover={{ bg: '#268594' }}
            flexGrow={1}
            onClick={handleSaveExit}
          >
            Save & Exit
          </Button>
        </HStack>
      </Stack>
    </VStack>
  );
};
