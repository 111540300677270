import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Text,
  Image,
  Icon,
  Link,
  theme,
  IconButton,
  useMediaQuery,
} from '@chakra-ui/react';
import logo from '../Assets/img/logo.png';
import logo1 from '../Assets/img/logo1.png';
import pp from '../Assets/img/pp.png';
import { FaHome, FaUserShield, FaCog, FaSignOutAlt } from 'react-icons/fa';
import { GlobalContext } from '../Context/GlobalContext';

export default function Sidebar() {
  const [checkRole, setCheckRole] = useState('Worker');
  const [name, setName] = useState('');
  const [pic, setPic] = useState('');

  useEffect(() => {
    setCheckRole(localStorage.getItem('role'));
    getUserById();
  }, []);

  const getUserById = async () => {
    const payload = { id: localStorage.getItem('userId') };
    console.log(payload);
    try {
      const res = await axios.post(
        '/user/getUserById',
        JSON.stringify(payload),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const status = res.status;
      if (status === 200) {
        console.log(res.data);
        setName(res.data.data.name);
        setPic(res.data.data.profileImage);
      }
    } catch (e) {
      console.log(e.response);
    }
  };

  const elems = [
    {
      text: 'Dashboard',
      icon: FaHome,
      link: '/dashboard',
      access: ['Admin', 'Founder', 'Manager', 'Worker'],
    },
    {
      text: 'User Management',
      icon: FaUserShield,
      link: '/user',
      access: ['Admin', 'Founder', 'Manager'],
    },
    {
      text: 'NGO Management',
      icon: FaUserShield,
      link: '/ngo',
      access: ['Admin', 'Founder'],
    },
    {
      text: 'Reporter Management',
      icon: FaUserShield,
      link: '/reporter',
      access: ['Admin', 'Founder', 'Manager', 'Worker'],
    },
    {
      text: 'Vet Management',
      icon: FaUserShield,
      link: '/vet',
      access: ['Admin', 'Founder', 'Manager'],
    },
    {
      text: 'Sponsor Management',
      icon: FaUserShield,
      link: '/sponsor',
      access: ['Admin', 'Founder'],
    },
    {
      text: 'Settings',
      icon: FaCog,
      link: '/settings',
      access: ['Admin', 'Founder', 'Manager', 'Worker'],
    },
  ];

  const [isSm] = useMediaQuery('(max-width: 500px)');

  const { expand } = useContext(GlobalContext);

  return (
    <Box
      zIndex='100'
      w={isSm ? '50px' : '75px'}
      minW={expand ? { base: '0px', sm: '300px' } : { base: '0px', sm: '0px' }}
      //   w='300px'
      _hover={{ base: { width: '300px' }, sm: { width: '300px' } }}
      position={isSm ? 'absolute' : ''}
      transitionDuration='.3s'
      transitionTimingFunction='ease-in-out'
      // minH={'100%'}
      h={'100vh'}
      boxShadow='0 14px 28px #00000040,0 10px 10px #00000038'
      bg='#f59120'
      display='flex'
      flexDirection='column'
      pl='5px'
      alignItems='start'
      overflow='hidden'
    >
      <Box
        w='250px'
        h='60px'
        display='flex'
        alignItems='center'
        justifyContent='start'
        pl={isSm ? '0px' : '10px'}
        gap='20px'
      >
        <Image
          src={logo}
          boxSize='40px'
          bg='#eee7da'
          boxShadow='0 10px 20px #00000030,0 6px 6px #0000003b'
          borderRadius='50%'
        />
        <Image
          src={logo1}
          w='130px'
          bg='#eee7da'
          boxShadow='0 10px 20px #00000030,0 6px 6px #0000003b'
          borderRadius='50%'
        />
      </Box>
      <Box w='300px' ml='-5px' h='1px' bg='#dfd6c6'></Box>
      <Box
        w='250px'
        h='60px'
        display='flex'
        alignItems='center'
        justifyContent='start'
        pl={isSm ? '0px' : '10px'}
        gap='20px'
      >
        <Image
          src={
            '/api/image/getImage/' +
            `${localStorage.getItem('userNgo')}` +
            '/' +
            pic
          }
          boxSize='40px'
          bg='#eee7da'
          boxShadow='0 10px 20px #00000030,0 6px 6px #0000003b'
          borderRadius='50%'
        />
        <Text>{name}</Text>
      </Box>
      <Box w='300px' ml='-5px' h='1px' bg='#dfd6c6'></Box>
      {elems
        .filter(elem => elem.access.includes(checkRole))
        .map(elem => (
          <SidebarElem link={elem.link} text={elem.text} icon={elem.icon} />
        ))}
    </Box>
  );
}

function SidebarElem(props) {
  const [isSm] = useMediaQuery('(max-width: 500px)');
  return (
    <Link as={NavLink} to={props.link} fontSize='15px' _hover={{}}>
      <Box
        w='300px'
        h='60px'
        ml={isSm ? '-5px' : '5px'}
        my='20px'
        pl={isSm ? '10px' : '12px'}
        gap='20px'
        display='flex'
        alignItems='center'
        _hover={{
          boxShadow: '0 1px 3px #0000001f, 0 1px 2px #0000003d',
          bg: '#eee7da',
        }}
        borderLeftRadius='30'
      >
        <Icon boxSize='30px' as={props.icon} />
        <Text>{props.text}</Text>
      </Box>
    </Link>
  );
}
