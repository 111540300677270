import { React, useState, useEffect, useContext } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import {
  ChakraProvider,
  useMediaQuery,
  Box,
  Text,
  Link,
  HStack,
  Stack,
  VStack,
  Code,
  Grid,
  theme,
} from '@chakra-ui/react';
import { GlobalProvider } from './Context/GlobalContext';
import { Logo } from './Logo';
import Login from './Pages/Login';
import AddCase from './Pages/AddCase';
import Footer from './Components/Footer';
import Topbar from './Components/Topbar';
import Dashboard from './Pages/Dashboard';
import './App.css';
import Sidebar from './Components/Sidebar';
import Settings from './Pages/Settings';
import UserManage from './Pages/User';
import AddUser from './Pages/AddUser';
import Ngo from './Pages/Ngo';
import AddNgo from './Pages/AddNgo';
import Reporter from './Pages/Reporter';
import AddReporter from './Pages/AddReporter';
import Vet from './Pages/Vet';
import AddVet from './Pages/AddVet';
import axios from 'axios';
import Sponsor from './Pages/Sponsor';
import AddSponsor from './Pages/AddSponsor';
axios.defaults.baseURL = `/api`;

function App() {
  const [isSm] = useMediaQuery('(max-width: 500px)');
  return (
    <ChakraProvider theme={theme}>
      <GlobalProvider>
        <Box w='100%' maxH='100vh' overflow='hidden'>
          <Router>
            <Routes>
              <Route path='/' element={<Login />} />
            </Routes>
            {/* </Router> */}
            <HStack
              bg='#eee7da'
              overflow='hidden'
              spacing={0}
              w={'100%'}
              h={'100vh'}
            >
              <Sidebar />
              <VStack
                pl={isSm ? '45px' : '0'}
                w={'100%'}
                justifyContent='space-between'
                overflowY='auto'
                h={'100vh'}
              >
                <Topbar />
                <Box flexGrow={1} w='100%'>
                  {/* <Router> */}
                  <Routes>
                    <Route path='dashboard' element={<Dashboard />} />
                    <Route path='dashboard/case' element={<AddCase />} />
                    {/* <Route path='dashboard/editcase' element={<EditCase />} /> */}
                    <Route path='user' element={<UserManage />} />
                    <Route path='user/adduser' element={<AddUser />} />
                    <Route path='settings' element={<Settings />} />
                    <Route path='ngo' element={<Ngo />} />
                    <Route path='ngo/addngo' element={<AddNgo />} />
                    <Route path='reporter' element={<Reporter />} />
                    <Route path='reporter/addreporter' element={<AddReporter />} />
                    <Route path='vet' element={<Vet />} />
                    <Route path='vet/addvet' element={<AddVet />} />
                    <Route path='sponsor' element={<Sponsor />} />
                    <Route path='sponsor/addsponsor' element={<AddSponsor />} />
                  </Routes>
                  {/* </Router> */}
                  {/* <UserManage />
            <AddUser />
            <Dashboard />
            <AddCase />
            <Settings /> */}
                </Box>
                <Footer />
              </VStack>
            </HStack>
          </Router>
        </Box>
      </GlobalProvider>
    </ChakraProvider>
  );
}

export default App;
