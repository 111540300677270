import React, { useEffect, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Text,
  Input,
  Link,
  Stack,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Button,
  Heading,
  useToast,
} from '@chakra-ui/react';
import {
  InputNormal,
  InputDate,
  InputTime,
  InputMenu,
  InputPhotos,
} from './Inputs';
import { GlobalContext } from '../Context/GlobalContext';

export default function OperationDetails() {
  const opts = ['Success', 'Failure', 'Complicated'];

  const {
    tabIndex,
    caseID,
    setTabIndex,
    editCase,
    vetName,
    setVetName,
    operationDate,
    setOperationDate,
    operationStartTime,
    setOperationStartTime,
    operationEndTime,
    setOperationEndTime,
    operationOutcome,
    setOperationOutcome,
    medicalPrescriptionImage,
    setMedicalPrescriptionImage,
    treatmentRecordImage,
    setTreatmentRecordImage,
    organImage,
    setOrganImage,
    opLastMod,
    setOpLastMod,
    opDetails,
    setOpDetails,
  } = useContext(GlobalContext);

  const toast = useToast();
  const AddOperationDetails = async () => {
    console.log('Adding Operation Details');
    console.log({
      vetName: vetName,
      operationDate: operationDate,
      operationStartTime: operationStartTime,
      operationEndTime: operationEndTime,
      operationOutcome: operationOutcome,
      medicalPrescriptionImage: medicalPrescriptionImage,
      treatmentRecordImage: treatmentRecordImage,
      organImage: organImage,
      // lastModifiedBy: opLastMod,
    });
    var data = {
      vetName: vetName,
      operationDate: operationDate,
      operationStartTime: operationStartTime,
      operationEndTime: operationEndTime,
      operationOutcome: operationOutcome,
      medicalPrescriptionImage: medicalPrescriptionImage,
      treatmentRecordImage: treatmentRecordImage,
      organImage: organImage,
      caseId: localStorage.getItem('caseId'),
      // caseId: caseID,
    };
    for (let prop in data) {
      if (
        data[`${prop}`] === '' ||
        data[`${prop}`] === null ||
        (Array.isArray(data[`${prop}`]) && data[`${prop}`].length === 0)
      ) {
        delete data[`${prop}`];
        console.log('removing prop : ', prop);
      }
    }
    console.log('edited data : ', data);
    try {
      const response = await axios.post('/case/updateOperationDetail', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status == 200) {
        console.log(response.data);
        setTabIndex(4);
      }
    } catch (error) {
      console.error(error);
      toast({
        title: 'Error',
        description: error.response.data.message,
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    }
  };

  const navigate = useNavigate();
  const handleSaveExit = () => {
    AddOperationDetails();
    navigate('/dashboard');
  };

  const handleSaveNext = () => {
    AddOperationDetails();
  };

  useEffect(() => {
    console.log(opDetails);
  }, []);

  return (
    <Stack spacing={8} w={'100%'} py={'10px'}>
      <Heading textAlign='start' fontSize={{ base: '2xl', md: '4xl' }}>
        Operation Details :
      </Heading>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: '2', md: '10' }}
      >
        <InputNormal
          value={vetName}
          setVar={setVetName}
          placeholder={'Vet Name'}
        >
          Vet Name
        </InputNormal>
        <InputDate
          value={operationDate}
          placeholder={'Operation date'}
          setVar={setOperationDate}
        >
          Operation Date
        </InputDate>
      </Stack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        alignItems='end'
        spacing={{ base: '2', md: '10' }}
      >
        <HStack w='100%' alignItems='end'>
          <InputTime
            value={operationStartTime}
            placeholder={'Operation Start Time'}
            setVar={setOperationStartTime}
          >
            Operation Start Time
          </InputTime>
          <InputTime
            value={operationEndTime}
            placeholder={'Operation End Time'}
            setVar={setOperationEndTime}
          >
            Operation End Time
          </InputTime>
        </HStack>
        <InputMenu
          val={operationOutcome}
          setVal={setOperationOutcome}
          options={opts}
        >
          Operation Outcome
        </InputMenu>
      </Stack>
      <InputPhotos
        value={medicalPrescriptionImage}
        setVar={setMedicalPrescriptionImage}
        field={'MedPresc'}
      >
        Medication Prescription
      </InputPhotos>
      <InputPhotos
        value={treatmentRecordImage}
        setVar={setTreatmentRecordImage}
        field={'TreatmentRec'}
      >
        Treatment Records
      </InputPhotos>
      <InputPhotos value={organImage} setVar={setOrganImage} field={'OrganPic'}>
        Organ Pictures
      </InputPhotos>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        w='100%'
        justifyContent='space-between'
      >
        <HStack justifyContent={{ base: 'space-between' }}>
          <Button
            bg='#17a2b8'
            size={{ base: 'sm', md: 'md' }}
            borderRadius='0.25rem'
            color='white'
            _hover={{ bg: '#268594' }}
            onClick={() => {
              setTabIndex(2);
            }}
          >
            Previous
          </Button>
          <Button
            bg='#17a2b8'
            size={{ base: 'sm', md: 'md' }}
            borderRadius='0.25rem'
            color='white'
            _hover={{ bg: '#268594' }}
          >
            <Link as={NavLink} to='/dashboard' fontSize='15px' _hover={{}}>
              Exit
            </Link>
          </Button>
        </HStack>
        <HStack justifyContent={{ base: 'space-between' }}>
          <Button
            bg='#17a2b8'
            size={{ base: 'sm', md: 'md' }}
            borderRadius='0.25rem'
            color='white'
            _hover={{ bg: '#268594' }}
            onClick={handleSaveExit}
          >
            Save & Exit
          </Button>
          <Button
            bg='#17a2b8'
            size={{ base: 'sm', md: 'md' }}
            borderRadius='0.25rem'
            color='white'
            _hover={{ bg: '#268594' }}
            onClick={handleSaveNext}
          >
            Save & Next
          </Button>
        </HStack>
      </Stack>
    </Stack>
  );
}
