import React from 'react';
import { Box, Text, Link, theme } from '@chakra-ui/react';

function Footer() {
  return (
    <Box
      w='100%'
      minH='60px'
      mx={16}
      bg='#eee7da'
      fontSize='16px'
      display='flex'
      color='#222222'
      justifyContent='center'
      alignItems='center'
      borderTop='1px solid #d8d6d4'
    >
      <Text textAlign='center'>
        <strong color='black'>Copyright © 2022 Sterilization</strong>. All
        rights reserved.
      </Text>
    </Box>
  );
}

export default Footer;
