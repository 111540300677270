import React, { useEffect, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Text,
  Input,
  Link,
  Stack,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Button,
  Heading,
  useToast,
} from '@chakra-ui/react';
import { InputNormal, InputDate, InputMenu, InputPhotos } from './Inputs';
import { GlobalContext } from '../Context/GlobalContext';

export default function MedicalDetails() {
  const fitOpts = ['Yes', 'No', 'Unsure'];
  const vacineOpts = [
    'Already Done',
    'To be done in NGO',
    'Not Done',
    'Unsure',
  ];
  const dewormOpts = [
    'Already Done',
    'To be done in NGO',
    'Not Done',
    'Unsure',
  ];

  const {
    caseID,
    tabIndex,
    setTabIndex,
    editCase,
    medicalHistory,
    setMedicalHistory,
    vaccinationStatus,
    setVaccinationStatus,
    dewormed,
    setDewormed,
    fitForSurgery,
    setFitForSurgery,
    otherDetails,
    setOtherDetails,
    admissionDate,
    setAdmissionDate,
    feedingRecordImage,
    setFeedingRecordImage,
    bloodReportImage,
    setBloodReportImage,
    medicalLastMod,
    setMedicalLastMod,
    medicalDetails,
    setMedicalDetails,
  } = useContext(GlobalContext);

  const toast = useToast();
  const AddMedicalDetails = async () => {
    console.log('Adding Medical Details');
    console.log({
      medicalHistory: medicalHistory,
      vaccinationStatus: vaccinationStatus,
      dewormed: dewormed,
      fitForSurgery: fitForSurgery,
      otherDetails: otherDetails,
      admissionDate: admissionDate,
      feedingRecordImage: feedingRecordImage,
      bloodReportImage: bloodReportImage,
      lastModifiedBy: medicalLastMod,
    });
    var data = {
      medicalHistory: medicalHistory,
      vaccinationStatus: vaccinationStatus,
      dewormed: dewormed,
      fitForSurgery: fitForSurgery,
      otherDetails: otherDetails,
      admissionDate: admissionDate,
      feedingRecordImage: feedingRecordImage,
      bloodReportImage: bloodReportImage,
      caseId: localStorage.getItem('caseId'),
      // caseId: caseID,
    };
    for (let prop in data) {
      if (
        data[`${prop}`] === '' ||
        data[`${prop}`] === null ||
        (Array.isArray(data[`${prop}`]) && data[`${prop}`].length === 0)
      ) {
        delete data[`${prop}`];
        console.log('removing prop : ', prop);
      }
    }
    console.log('edited data : ', data);
    try {
      const response = await axios.post('/case/updateMedicalDetail', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status == 200) {
        console.log(response.data);
        setTabIndex(3);
      }
    } catch (error) {
      console.error(error);
      toast({
        title: 'Error',
        description: error.response.data.message,
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    }
  };

  const navigate = useNavigate();
  const handleSaveExit = () => {
    AddMedicalDetails();
    navigate('/dashboard');
  };

  const handleSaveNext = () => {
    AddMedicalDetails();
  };

  useEffect(() => {
    console.log(medicalDetails);
  }, []);

  return (
    <Stack spacing={8} w={'100%'} py={'10px'}>
      <Heading textAlign='start' fontSize={{ base: '2xl', md: '4xl' }}>
        Medical Details :
      </Heading>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: '2', md: '10' }}
      >
        <InputNormal
          setVar={setMedicalHistory}
          placeholder={
            editCase
              ? medicalDetails.medicalHistory
              : 'Medical History / Other Issues'
          }
        >
          Medical History / Other Issues
        </InputNormal>
        <InputMenu
          val={vaccinationStatus}
          setVal={setVaccinationStatus}
          options={vacineOpts}
        >
          Animal Vaccinated
        </InputMenu>
        <InputMenu val={dewormed} setVal={setDewormed} options={dewormOpts}>
          Animal Dewormed
        </InputMenu>
      </Stack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: '2', md: '10' }}
      >
        <InputMenu
          val={fitForSurgery}
          setVal={setFitForSurgery}
          options={fitOpts}
        >
          Animal Fit for Surgery
        </InputMenu>
        <InputNormal
          value={otherDetails}
          setVar={setOtherDetails}
          placeholder={editCase ? medicalDetails.otherDetails : 'Other Details'}
        >
          Other Details
        </InputNormal>
        <InputDate
          value={admissionDate}
          placeholder={'Admission Date'}
          setVar={setAdmissionDate}
        >
          Admission Date
        </InputDate>
      </Stack>
      <InputPhotos
        value={bloodReportImage}
        setVar={setBloodReportImage}
        field={'BloodReport'}
      >
        Blood Report Pictures
      </InputPhotos>
      <InputPhotos
        value={feedingRecordImage}
        setVar={setFeedingRecordImage}
        field={'FeedingRecord'}
      >
        Feeding Record Pictures
      </InputPhotos>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        w='100%'
        justifyContent='space-between'
      >
        <HStack justifyContent={{ base: 'space-between' }}>
          <Button
            bg='#17a2b8'
            size={{ base: 'sm', md: 'md' }}
            borderRadius='0.25rem'
            color='white'
            _hover={{ bg: '#268594' }}
            onClick={() => {
              setTabIndex(1);
            }}
          >
            Previous
          </Button>
          <Button
            bg='#17a2b8'
            size={{ base: 'sm', md: 'md' }}
            borderRadius='0.25rem'
            color='white'
            _hover={{ bg: '#268594' }}
          >
            <Link as={NavLink} to='/dashboard' fontSize='15px' _hover={{}}>
              Exit
            </Link>
          </Button>
        </HStack>
        <HStack justifyContent={{ base: 'space-between' }}>
          <Button
            bg='#17a2b8'
            size={{ base: 'sm', md: 'md' }}
            borderRadius='0.25rem'
            color='white'
            _hover={{ bg: '#268594' }}
            onClick={handleSaveExit}
          >
            Save & Exit
          </Button>
          <Button
            bg='#17a2b8'
            size={{ base: 'sm', md: 'md' }}
            borderRadius='0.25rem'
            color='white'
            _hover={{ bg: '#268594' }}
            onClick={handleSaveNext}
          >
            Save & Next
          </Button>
        </HStack>
      </Stack>
    </Stack>
  );
}
