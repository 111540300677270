import { React, useState, useEffect, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Text,
  Input,
  Link,
  Stack,
  Flex,
  FormControl,
  FormLabel,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  InputGroup,
  HStack,
  InputRightElement,
  Button,
  Heading,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { GlobalContext } from '../Context/GlobalContext';
import { InputNormal, InputPhoto, InputPhotos } from '../Components/Inputs';
import { AddIcon } from '@chakra-ui/icons';

export default function AddNgo() {
  const {
    editNgo,
    setEditNgo,
    viewNgoId,
    setViewNgoId,
    ngoName,
    setNgoName,
    darpanId,
    setDarpanId,
    registrationCertificate,
    setRegistrationCertificate,
    panCard,
    setPanCard,
    logo,
    setLogo,
    description,
    setDescription,
    missionStatement,
    setMissionStatement,
    facilityPictures,
    setFacilityPictures,
    helplineNumber1,
    setHelplineNumber1,
    helplineNumber2,
    setHelplineNumber2,
    facebookUrl,
    setFacebookUrl,
    linkedinUrl,
    setLinkedinUrl,
    instagramId,
    setInstagramId,
    twitterId,
    setTwitterId,
    email,
    setEmail,
    websiteUrl,
    setWebsiteUrl,
    ngoDetails,
    setNgoDetails,
  } = useContext(GlobalContext);

  const navigate = useNavigate();
  const toast = useToast();
  const handleAddNgo = async () => {
    const url = editNgo ? '/ngo/updateNgo' : '/ngo/createNgo';
    if (ngoName === '') {
      toast({
        title: 'Error',
        description: 'Please fill Required Details',
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    } else {
      var data = {
        name: ngoName,
        darpanId: darpanId,
        desc: description,
        mission: missionStatement,
        helpline: helplineNumber1,
        altHelpline: helplineNumber2,
        facebook: facebookUrl,
        linkedin: linkedinUrl,
        instagram: instagramId,
        twitter: twitterId,
        email: email,
        website: websiteUrl,
      };
      if (editNgo) {
        data.ngoId = localStorage.getItem('ngoId');
        data.regCert = registrationCertificate;
        data.panCard = panCard;
        data.logo = logo;
        data.facility = facilityPictures;
      }
      for (let prop in data) {
        if (
          data[`${prop}`] === '' ||
          data[`${prop}`] === null ||
          (Array.isArray(data[`${prop}`]) && data[`${prop}`].length === 0)
        ) {
          delete data[`${prop}`];
          console.log('removing prop : ', prop);
        }
      }
      console.log('edited data : ', data);
      try {
        const res = await axios.post(url, JSON.stringify(data), {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const status = res.status;
        if (status === 200) {
          console.log(res.data);
          toast({
            title: 'Done',
            description: 'NGO created successfully',
            status: 'success',
            duration: 800,
            isClosable: true,
          });
          setTimeout(() => {
            navigate('/ngo');
          }, 800);
        }
      } catch (e) {
        console.log(e);
        toast({
          title: 'Error',
          description: e.response.data.message,
          status: 'error',
          duration: 10000,
          isClosable: true,
        });
      }
    }
  };

  const getNgoById = async () => {
    var data = { ngoId: localStorage.getItem('ngoId') };
    const response = await axios.post('/ngo/getNgoById', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status == 200) {
      setNgoDetails(response.data.data);
      const ngodetails = response.data.data;
      setNgoName(ngodetails.name);
      setDarpanId(ngodetails.darpanId);
      setRegistrationCertificate(ngodetails.regCert);
      setPanCard(ngodetails.panCard);
      setLogo(ngodetails.logo);
      setDescription(ngodetails.desc);
      setMissionStatement(ngodetails.mission);
      setFacilityPictures(ngodetails.facility);
      setHelplineNumber1(ngodetails.helpline);
      setHelplineNumber2(ngodetails.altHelpline);
      setFacebookUrl(ngodetails.facebook);
      setLinkedinUrl(ngodetails.linkedin);
      setInstagramId(ngoDetails.instagram);
      setTwitterId(ngoDetails.twitter);
      setEmail(ngoDetails.email);
      setWebsiteUrl(ngoDetails.website);
    } else {
      console.error(response.data);
    }
  };

  const clearContext = async () => {
    setNgoName('')
    setDarpanId('')
    setRegistrationCertificate([])
    setPanCard([])
    setLogo('')
    setDescription('')
    setMissionStatement('')
    setFacilityPictures([])
    setHelplineNumber1('')
    setHelplineNumber2('')
    setFacebookUrl('')
    setLinkedinUrl('')
    setInstagramId('')
    setTwitterId('')
    setEmail('')
    setWebsiteUrl('')
  }


  useEffect(() => {
    if (localStorage.getItem('editNgo')) getNgoById();
    else clearContext()
  }, []);

  // useEffect(() => {
  //   // setEditNgo(localStorage.getItem('editNgo'));
  //   if (editNgo || localStorage.getItem('editNgo')){
  //     getNgoById();
  //   } else clearContext();
  // }, [editNgo]);

  return (
    <Box w='100%' px={'15px'} pb='20px'>
      <Stack
        justify={'space-between'}
        w={'100%'}
        align={'center'}
        direction={'row'}
      >
        <Text
          py='19px'
          fontWeight='semibold'
          fontSize={{ base: '1.3rem', sm: '1.8rem' }}
        >
          {localStorage.getItem('editNgo') ? 'Edit Ngo' : 'Add Ngo'}
        </Text>
        <Text
          py='19px'
          fontWeight='semibold'
          fontSize={{ base: '1rem', sm: '1.2rem' }}
        >
          <Link color={'blue.400'}>Dashboard</Link> /{' '}
          {localStorage.getItem('editNgo') ? 'Edit Ngo' : 'Add Ngo'}
        </Text>
      </Stack>
      <VStack
        w='100%'
        bg='white'
        p='30px'
        border='0 solid rgba(0,0,0,.125)'
        borderRadius='0.25rem'
        boxShadow='0 0 1px rgb(0 0 0 / 13%), 0 1px 3px #0003'
        spacing={{ base: '2', md: '10' }}
      >
        <Heading
          w='100%'
          textAlign='start'
          fontSize={{ base: '2xl', md: '4xl' }}
        >
          Ngo Details :
        </Heading>
        <Stack
          w='100%'
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '2', md: '10' }}
        >
          <InputNormal
            value={ngoName}
            setVar={setNgoName}
            placeholder={'Ngo Name'}
            required={editNgo ? false : true}
          >
            Ngo Name
          </InputNormal>
          <InputNormal
            value={darpanId}
            setVar={setDarpanId}
            placeholder={'Darpan ID'}
            required={editNgo ? false : true}
          >
            Darpan Id
          </InputNormal>
          <InputNormal
            value={description}
            setVar={setDescription}
            placeholder={'Ngo Description'}
          >
            Ngo Description
          </InputNormal>
        </Stack>
        <Stack
          w='100%'
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '2', md: '10' }}
        >
          <InputNormal
            value={missionStatement}
            setVar={setMissionStatement}
            placeholder={'Mission Statement'}
          >
            Mission Statement
          </InputNormal>
          <InputNormal
            value={helplineNumber1}
            setVar={setHelplineNumber1}
            placeholder={'Helpline Number'}
          >
            Helpline Number
          </InputNormal>
          <InputNormal
            vlaue={helplineNumber2}
            setVar={setHelplineNumber2}
            placeholder={'Alternate Helpline Number'}
          >
            Alternate Helpline Number
          </InputNormal>
        </Stack>
        <Stack
          w='100%'
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '2', md: '10' }}
        >
          <InputNormal
            value={facebookUrl}
            setVar={setFacebookUrl}
            placeholder={'Facebook URL'}
          >
            FaceBook Page
          </InputNormal>
          <InputNormal
            value={linkedinUrl}
            setVar={setLinkedinUrl}
            placeholder={'Linkedin URL'}
          >
            LinkedIn Page
          </InputNormal>
          <InputNormal
            value={instagramId}
            setVar={setInstagramId}
            placeholder={'Instagram ID'}
          >
            Instagram Page
          </InputNormal>
        </Stack>
        <Stack
          w='100%'
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '2', md: '10' }}
        >
          <InputNormal
            value={twitterId}
            setVar={setTwitterId}
            placeholder={'Twitter ID'}
          >
            FaceBook Page
          </InputNormal>
          <InputNormal
            value={email}
            setVar={setEmail}
            placeholder={'NGO Email'}
          >
            NGO Email
          </InputNormal>
          <InputNormal
            value={websiteUrl}
            setVar={setWebsiteUrl}
            placeholder={'Website URL'}
          >
            Website URL
          </InputNormal>
        </Stack>
        {localStorage.getItem('editNgo') && (
          <VStack w='100%'>
            <InputPhoto value={logo} setVar={setLogo} field={'Logo'}>
              NGO Logo
            </InputPhoto>
            <Stack
              w='100%'
              direction={{ base: 'column', md: 'row' }}
              spacing={{ base: '2', md: '10' }}
            >
              <InputPhotos
                value={registrationCertificate}
                setVar={setRegistrationCertificate}
                field={'RegCert'}
              >
                Registration Certificate
              </InputPhotos>
              <InputPhotos
                value={panCard}
                setVar={setPanCard}
                field={'PanCard'}
              >
                Pan Card
              </InputPhotos>
            </Stack>
            <InputPhotos
              value={facilityPictures}
              setVar={setFacilityPictures}
              field={'Facility'}
            >
              NGO Facility Pictures
            </InputPhotos>
          </VStack>
        )}

        <Box
          w='100%'
          mt='30px'
          h='100px'
          display='flex'
          alignItems='center'
          justifyContent='center'
          borderTop='1px solid #d8d6d4'
        >
          <Button
            h='40px'
            bg='#f59120'
            color='white'
            borderRadius='0.25rem'
            fontSize='15px'
            _hover={{}}
            onClick={handleAddNgo}
          >
            {editNgo ? 'Save' : 'Add'}
          </Button>
        </Box>
      </VStack>
    </Box>
  );
}
