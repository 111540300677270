import React, { useEffect, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Text,
  Input,
  Link,
  Stack,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Button,
  Heading,
  useToast,
} from '@chakra-ui/react';
import {
  InputNormal,
  InputDate,
  InputTime,
  InputMenu,
  InputPhotos,
} from './Inputs';
import { GlobalContext } from '../Context/GlobalContext';

export default function OperationDetails() {
  const facilityOpts = ['In Shelter', 'Not in shelter', 'On Street', 'Other'];

  const {
    caseID,
    tabIndex,
    setTabIndex,
    editCase,
    popComment,
    setPopComment,
    popFacility,
    setPopFacility,
    popExpectedDays,
    setPopExpectedDays,
    popStartDate,
    setPopStartDate,
    popEndDate,
    setPopEndDate,
    releaseDate,
    setReleaseDate,
    comments,
    setComments,
    popPictures,
    setPopPictures,
    releasePictures,
    setReleasePictures,
    euthanized,
    setEuthanized,
    popLocation,
    setPopLocation,
    popDetails,
    setPopDetails,
    popLastMod,
    setPopLastMod,
  } = useContext(GlobalContext);

  const toast = useToast();
  const navigate = useNavigate();
  const AddPopDetails = async () => {
    console.log('Adding Operation Details');
    console.log({
      popComment: popComment,
      popFacility: popFacility,
      popExpectedDays: popExpectedDays,
      popStartDate: popStartDate,
      popEndDate: popEndDate,
      releaseDate: releaseDate,
      comments: comments,
      popPictures: popPictures,
      releasePictures: releasePictures,
      euthanized: euthanized,
      popLocation: popLocation,
      // lastModifiedBy: popLastMod,
    });
    var data = {
      popComment: popComment,
      popFacility: popFacility,
      popExpectedDays: popExpectedDays,
      popStartDate: popStartDate,
      popEndDate: popEndDate,
      releaseDate: releaseDate,
      comments: comments,
      popPictures: popPictures,
      releasePictures: releasePictures,
      euthanized: euthanized,
      popLocation: popLocation,
      caseId: localStorage.getItem('caseId'),
      // caseId: caseID,
    };
    for (let prop in data) {
      if (
        data[`${prop}`] === '' ||
        data[`${prop}`] === null ||
        (Array.isArray(data[`${prop}`]) && data[`${prop}`].length === 0)
      ) {
        delete data[`${prop}`];
        console.log('removing prop : ', prop);
      }
    }
    console.log('edited data : ', data);
    try {
      const response = await axios.post(
        '/case/updatePostOperationDetail',
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status == 200) {
        console.log(response.data);
        navigate('/dashboard');
      }
    } catch (error) {
      console.error(error);
      toast({
        title: 'Error',
        description: error.response.data.message,
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    }
  };

  const handleSaveExit = () => {
    AddPopDetails();
  };

  useEffect(() => {
    console.log(popDetails);
  }, []);

  return (
    <Stack spacing={8} w={'100%'} py={'10px'}>
      <Heading textAlign='start' fontSize={{ base: '2xl', md: '4xl' }}>
        Post Operation Details :
      </Heading>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: '2', md: '10' }}
      >
        <InputNormal
          value={popComment}
          setVar={setPopComment}
          placeholder={'Comments'}
        >
          Post-Operation Comments
        </InputNormal>
        <InputMenu
          val={popFacility}
          setVal={setPopFacility}
          options={facilityOpts}
        >
          Post-Operation facility
        </InputMenu>
        <InputNormal
          value={popExpectedDays}
          setVar={setPopExpectedDays}
          placeholder={'Expected Days'}
        >
          Post-Operation Expected Days
        </InputNormal>
      </Stack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        alignItems='end'
        spacing={{ base: '2', md: '10' }}
      >
        <InputDate
          value={popStartDate}
          placeholder={''}
          setVar={setPopStartDate}
        >
          Post-Operation Start Date
        </InputDate>
        <InputDate value={popEndDate} setVar={setPopEndDate}>
          Post-Operation End Date
        </InputDate>
        <InputDate value={releaseDate} setVar={setReleaseDate}>
          Release Date
        </InputDate>
      </Stack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: '2', md: '10' }}
      >
        <InputMenu
          val={euthanized}
          setVal={setEuthanized}
          options={['Yes', 'No']}
        >
          Euthanized
        </InputMenu>
        <InputNormal
          value={comments}
          setVar={setComments}
          placeholder={'Other Comments'}
        >
          Other Comments
        </InputNormal>
      </Stack>
      <InputPhotos
        value={popPictures}
        setVar={setPopPictures}
        field={'PopPics'}
      >
        Post-Operation Pictures
      </InputPhotos>
      <InputPhotos
        value={releasePictures}
        setVar={setReleasePictures}
        field={'ReleasePics'}
      >
        Release Pictures
      </InputPhotos>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        w='100%'
        justifyContent='space-between'
      >
        <HStack justifyContent={{ base: 'space-between' }}>
          <Button
            bg='#17a2b8'
            size={{ base: 'sm', md: 'md' }}
            borderRadius='0.25rem'
            color='white'
            _hover={{ bg: '#268594' }}
            onClick={() => {
              setTabIndex(3);
            }}
          >
            Previous
          </Button>
          <Button
            bg='#17a2b8'
            size={{ base: 'sm', md: 'md' }}
            borderRadius='0.25rem'
            color='white'
            _hover={{ bg: '#268594' }}
          >
            <Link as={NavLink} to='/dashboard' fontSize='15px' _hover={{}}>
              Exit
            </Link>
          </Button>
        </HStack>
        <HStack justifyContent={{ base: 'space-between' }}>
          <Button
            bg='#17a2b8'
            size={{ base: 'sm', md: 'md' }}
            borderRadius='0.25rem'
            color='white'
            _hover={{ bg: '#268594' }}
            onClick={handleSaveExit}
          >
            Save & Exit
          </Button>
        </HStack>
      </Stack>
    </Stack>
  );
}
