import { React, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Text,
  Input,
  Link,
  theme,
  IconButton,
  Icon,
  Switch,
  Button,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Tooltip,
  HStack,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa';
import { GlobalContext } from '../Context/GlobalContext';

export default function UserManage() {
  const tableHeadings = [
    'S.No',
    'User Name',
    'Email',
    'Type',
    'Added User',
    'Status',
  ];

  const [userData, setUserData] = useState([]);

  const {
    editUser,
    setEditUser,
    viewUserId,
    setViewUserId,
    userDetails,
    setUserDetails,
    setName,
    setEmail,
    setUsername,
    setPassword,
    setContact,
    setProfileImage,
    setVerificationId,
    setRole,
  } = useContext(GlobalContext);

  const clearContext = async () => {
    setName('');
    setEmail('');
    setUsername('');
    setPassword('');
    setContact('');
    setProfileImage('');
    setVerificationId([]);
    setRole('');
  };

  useEffect(() => {
    localStorage.removeItem('ngoId');
    localStorage.removeItem('caseId');
    localStorage.removeItem('editUser');
    localStorage.removeItem('viewUserId');
    localStorage.removeItem('editNgo');
    localStorage.removeItem('editCase');
    clearContext();
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const res = await axios.post('/user/getUsers', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const status = res.status;
      if (status === 200) {
        console.log(res.data);
        setUserData(res.data.data);
      }
    } catch (e) {
      console.log(e.response);
    }
  };

  async function handleDeleteUser(id) {
    try {
      const res = await axios.post(
        '/user/deleteUser',
        { id: id },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const status = res.status;
      if (status === 200) {
        console.log(res.data);
        getUsers();
      }
    } catch (e) {
      console.log(e.response);
    }
  }

  return (
    <Box w='100%' px='20px' pb='20px'>
      <Text py='19px' fontWeight='semibold' fontSize='1.8rem'>
        ParentNGO List
      </Text>
      <Box
        w='100%'
        h='950px'
        bg='white'
        border='0 solid rgba(0,0,0,.125)'
        borderRadius='0.25rem'
        boxShadow='0 0 1px rgb(0 0 0 / 13%), 0 1px 3px #0003'
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        <Box
          w='100%'
          p='30px'
          mb='20px'
          gap={{ base: '20%', md: '50%' }}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Link as={NavLink} to='/user/adduser' _hover={{}}>
            <Button
              minW='120px'
              h='40px'
              bg='#f59120'
              color='white'
              borderRadius='0.25rem'
              fontSize='15px'
              leftIcon={<AddIcon />}
              onClick={setEditUser(false)}
              _hover={{}}
            >
              Add User
            </Button>
          </Link>
          <Input
            size='md'
            borderRadius='0.25rem'
            placeholder='Search by Name / Email'
          />
        </Box>
        <Box w='calc(100% - 60px)' bg='' minH='640px' overflow='auto'>
          <TableContainer>
            <Table variant='striped'>
              <Thead>
                <Tr>
                  {tableHeadings.map(th => (
                    <ThN>{th}</ThN>
                  ))}
                </Tr>
              </Thead>

              <Tbody>
                {userData.map((data, index) => (
                  <Tr>
                    <Td fontSize='16px' p='0px'>
                      <Box
                        w='100%'
                        h='60px'
                        display='flex'
                        px='10px'
                        gap=''
                        justifyContent='center'
                        alignItems='center'
                        borderLeft='1px solid #d8d6d4'
                        borderBottom='1px solid #d8d6d4'
                      >
                        {index + 1}
                      </Box>
                    </Td>
                    <Td fontSize='16px' p='0px'>
                      <Box
                        w='100%'
                        h='60px'
                        display='flex'
                        px=''
                        gap=''
                        justifyContent='center'
                        alignItems='center'
                        borderLeft='1px solid #d8d6d4'
                        borderBottom='1px solid #d8d6d4'
                      >
                        {data.username}
                      </Box>
                    </Td>
                    <Td fontSize='16px' p='0px'>
                      <Box
                        w='100%'
                        h='60px'
                        display='flex'
                        px=''
                        gap=''
                        justifyContent='center'
                        alignItems='center'
                        borderLeft='1px solid #d8d6d4'
                        borderBottom='1px solid #d8d6d4'
                      >
                        {data.email}
                      </Box>
                    </Td>
                    <Td fontSize='16px' p='0px'>
                      <Box
                        w='100%'
                        h='60px'
                        display='flex'
                        px=''
                        gap=''
                        justifyContent='center'
                        alignItems='center'
                        borderLeft='1px solid #d8d6d4'
                        borderBottom='1px solid #d8d6d4'
                      >
                        {data.role}
                      </Box>
                    </Td>
                    <Td fontSize='16px' p='0px'>
                      <Box
                        w='100%'
                        h='60px'
                        display='flex'
                        px='5px'
                        gap=''
                        justifyContent='center'
                        alignItems='center'
                        borderLeft='1px solid #d8d6d4'
                        borderBottom='1px solid #d8d6d4'
                      >
                        <Link as={NavLink} to='/user/adduser' _hover={{}}>
                          <Button
                            size='sm'
                            bg='#f59120'
                            color='white'
                            _hover={{ bg: '#a85b1b ' }}
                            borderRadius='3px'
                            onClick={() => {
                              setEditUser(true);
                              localStorage.setItem('ngoId', data.ngoId);
                              localStorage.setItem('editUser', true);
                              localStorage.setItem('viewUserId', data.id);
                              setViewUserId(data.id);
                            }}
                          >
                            View
                          </Button>
                        </Link>
                      </Box>
                    </Td>
                    {/* <Td fontSize='16px' p='0px'>
                      <Box
                        w='100%'
                        h='60px'
                        display='flex'
                        px=''
                        gap=''
                        justifyContent='center'
                        alignItems='center'
                        borderLeft='1px solid #d8d6d4'
                        borderBottom='1px solid #d8d6d4'
                      >
                        <Text
                          borderRadius='0.25rem'
                          p='5px'
                          color='white'
                          bg={data.isActive ? '#28a745' : '#dc3545'}
                        >
                          {data.isActive ? 'Active' : 'Deactive'}
                        </Text>
                      </Box>
                    </Td> */}
                    <Td fontSize='16px' p='0px'>
                      <Box
                        w='100%'
                        h='60px'
                        display='flex'
                        px=''
                        gap=''
                        justifyContent='center'
                        alignItems='center'
                        borderLeft='1px solid #d8d6d4'
                        borderBottom='1px solid #d8d6d4'
                      >
                        <HStack>
                          {/* <Switch id='email-alerts' />
                          <IconButton
                            bg=''
                            fontSize='20px'
                            color='#ffc107'
                            borderRadius='0.25rem'
                            _hover={{ color: '#c99700' }}
                            icon={<FaEye />}
                          />
                          <IconButton
                            bg=''
                            fontSize='20px'
                            color='#343a40'
                            borderRadius='0.25rem'
                            _hover={{ color: 'black' }}
                            icon={<FaPen />}
                          /> */}
                          <IconButton
                            bg=''
                            fontSize='20px'
                            color='#dc3545'
                            borderRadius='0.25rem'
                            _hover={{ color: '#b41323' }}
                            icon={<FaTrash />}
                            onClick={() => {
                              handleDeleteUser(data.id);
                            }}
                          />
                        </HStack>
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
              {/* <Tfoot>
                  <Tr>
                    <Th>To convert</Th>
                    <Th>into</Th>
                    <Th isNumeric>multiply by</Th>
                  </Tr>
                </Tfoot> */}
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
}

const ThN = ({ children }) => {
  return (
    <Th border='1px solid #d8d6d4' color='black'>
      <Text textAlign='center' fontSize='16px'>
        {children}
      </Text>
    </Th>
  );
};
