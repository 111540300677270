import { React, useState, useEffect, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Text,
  Input,
  Link,
  Stack,
  Flex,
  FormControl,
  FormLabel,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  InputGroup,
  HStack,
  InputRightElement,
  Button,
  Heading,
  useMultiStyleConfig,
  useColorModeValue,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { GlobalContext } from '../Context/GlobalContext';
import { InputNormal, InputPhoto, InputPhotos, InputMenu } from '../Components/Inputs';

export default function AddVet() {
  const {
    editVet,
    //vet
    vetName,
    setVetName,
    vetRegId,
    setVetRegId,
    certification,
    setCertification,
    vetVerificationId,
    setVetVerificationId,
  } = useContext(GlobalContext);

  const [userRole, setUserRole] = useState(localStorage.getItem('role'));
  const [ngolist, setngolist] = useState([]);
  const [ngoName, setNgoName] = useState('');

  const getNgos = async () => {
    try {
      const res = await axios.post('/ngo/getNgos', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const status = res.status;
      if (status === 200) {
        console.log(res.data);
        setngolist(res.data.data.map(a => a.name));
      }
    } catch (e) {
      console.log(e.response);
    }
  };

  const getNgoById = async () => {
    var data = { ngoId: localStorage.getItem('ngoId') };
    const response = await axios.post('/ngo/getNgoById', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status == 200) {
      const ngodetails = response.data.data;
      setNgoName(ngodetails.name);
    } else {
      console.error(response.data);
    }
  };

  const getVetById = async () => {
    const payload = { id: localStorage.getItem('vetId') };
    console.log(payload);
    try {
      const res = await axios.post(
        '/vet/getVetById',
        JSON.stringify(payload),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const status = res.status;
      if (status === 200) {
        console.log(res.data);
        setVetName(res.data.data.name);
        setVetRegId(res.data.data.regId);
        setCertification(res.data.data.vetCertification);
        setVetVerificationId(res.data.data.verificationId);
      }
    } catch (e) {
      console.log(e.response);
    }
  };

  useEffect(() => {
    getNgos();
    if (localStorage.getItem('editVet')) {
      getVetById();
      getNgoById();
    } 
  }, []);

  const navigate = useNavigate();
  const toast = useToast();
  const handleAddVet = async () => {
    const url = editVet ? '/vet/updateVet' : '/vet/createVet';
    if (vetName == '' || vetRegId == '') {
      toast({
        title: 'Error',
        description: 'Please fill Required Details',
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    } else {
      var data = {
        name: vetName,
        regId: vetRegId,
        vetCertification: certification,
        verificationId: vetVerificationId,
      };
      if (localStorage.getItem('role') === 'Admin') data.ngoName = ngoName;
      for (let prop in data) {
        if (
          data[`${prop}`] === '' ||
          data[`${prop}`] === null ||
          (Array.isArray(data[`${prop}`]) && data[`${prop}`].length === 0)
        ) {
          delete data[`${prop}`];
          console.log('removing prop : ', prop);
        }
      }
      console.log('edited data : ', data);
      try {
        const res = await axios.post(url, JSON.stringify(data), {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const status = res.status;
        if (status === 200) {
          console.log(res.data);
          toast({
            title: 'Done',
            description: 'Vet created successfully',
            status: 'success',
            duration: 800,
            isClosable: true,
          });
          setTimeout(() => {
            navigate('/vet');
          }, 800);
        }
      } catch (e) {
        console.log(e);
        toast({
          title: 'Error',
          description: e.response.data.message,
          status: 'error',
          duration: 10000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Box w='100%' px={'15px'} pb='20px'>
      <Stack
        justify={'space-between'}
        w={'100%'}
        align={'center'}
        direction={'row'}
      >
        <Text
          py='19px'
          fontWeight='semibold'
          fontSize={{ base: '1.3rem', sm: '1.8rem' }}
        >
          Add Vet
        </Text>
        <Text
          py='19px'
          fontWeight='semibold'
          fontSize={{ base: '1rem', sm: '1.2rem' }}
        >
          <Link color={'blue.400'}>Dashboard</Link> / Add Vet
        </Text>
      </Stack>

      <VStack
        w='100%'
        bg='white'
        p='30px'
        border='0 solid rgba(0,0,0,.125)'
        borderRadius='0.25rem'
        boxShadow='0 0 1px rgb(0 0 0 / 13%), 0 1px 3px #0003'
        spacing={{ base: '2', md: '10' }}
      >
        <Heading
          w='100%'
          textAlign='start'
          fontSize={{ base: '2xl', md: '4xl' }}
        >
          Vet Details :
        </Heading>

        <Stack
          w='100%'
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '2', md: '10' }}
        >
          <InputNormal
            value={vetName}
            setVar={setVetName}
            placeholder={'Name'}
            required={true}
          >
            Vet name
          </InputNormal>
          <InputNormal
            value={vetRegId}
            setVar={setVetRegId}
            placeholder={'Registration Id'}
            required={true}
          >
            Registration ID
          </InputNormal>
        </Stack>
        <Stack
          w='100%'
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '2', md: '10' }}
        >
          <InputPhoto
            value={certification}
            setVar={setCertification}
            field={'Certification'}
          >
            Vet Certification
          </InputPhoto>
          <InputPhotos
            value={vetVerificationId}
            setVar={setVetVerificationId}
            field={'Verification'}
          >
            Verification ID
          </InputPhotos>
        </Stack>
        {userRole == 'Admin' && (
          <InputMenu
            options={ngolist}
            val={ngoName}
            setVal={setNgoName}
            // required={editUser ? false : true}
          >
            NGO
          </InputMenu>
        )}

        <Box
          w='100%'
          mt='30px'
          h='100px'
          display='flex'
          alignItems='center'
          justifyContent='center'
          borderTop='1px solid #d8d6d4'
        >
          <Button
            h='40px'
            bg='#f59120'
            color='white'
            borderRadius='0.25rem'
            fontSize='15px'
            _hover={{}}
            onClick={handleAddVet}
          >
            Save
          </Button>
        </Box>
      </VStack>
    </Box>
  );
}
