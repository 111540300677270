import { React, useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Text,
  Input,
  Link,
  Stack,
  Flex,
  FormControl,
  FormLabel,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  InputGroup,
  HStack,
  InputRightElement,
  Button,
  Heading,
  VStack,
  useToast,
  Image,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';
import { GlobalContext } from '../Context/GlobalContext';
import { InputNormal } from '../Components/Inputs';
import { AddIcon } from '@chakra-ui/icons';

export default function Ngo() {
  const {
    viewNgoId,
    setViewNgoId,
    editNgo,
    setEditNgo,
    ngoDetails,
    setNgoDetails,
  } = useContext(GlobalContext);

  const [ngoData, setNgoData] = useState([]);

  const tableHeadings = ['S.No', 'Name', 'Darpan Id', 'Logo', 'Edit'];

  const getNgoDetails = async () => {
    const response = await axios.post('/ngo/getNgos');
    if (response.status === 200) {
      setNgoData(response.data.data);
      console.log(response.data.data);
    } else {
      console.log(response.data);
    }
  };

  useEffect(() => {
    getNgoDetails();
    localStorage.removeItem('editNgo');
    localStorage.removeItem('caseId');
    localStorage.removeItem('ngoId');
    localStorage.removeItem('editUser');
    localStorage.removeItem('viewUserId');
    localStorage.removeItem('editCase');
  }, []);

  return (
    <Box w='100%' px={'15px'} pb='20px'>
      <Stack
        justify={'space-between'}
        w={'100%'}
        align={'center'}
        direction={'row'}
      >
        <Text
          py='19px'
          fontWeight='semibold'
          fontSize={{ base: '1.3rem', sm: '1.8rem' }}
        >
          Ngo Management
        </Text>
        <Text
          py='19px'
          fontWeight='semibold'
          fontSize={{ base: '1rem', sm: '1.2rem' }}
        >
          <Link color={'blue.400'}>Dashboard</Link> / Ngo
        </Text>
      </Stack>
      <VStack
        w='100%'
        bg='white'
        border='0 solid rgba(0,0,0,.125)'
        borderRadius='0.25rem'
        boxShadow='0 0 1px rgb(0 0 0 / 13%), 0 1px 3px #0003'
      >
        <HStack w='100%' p='30px' mb='20px' justifyContent='space-between'>
          <Link as={NavLink} to='/ngo/addngo' _hover={{}}>
            <Button
              minW='120px'
              h='40px'
              bg='#f59120'
              color='white'
              borderRadius='0.25rem'
              fontSize='15px'
              leftIcon={<AddIcon />}
              onClick={setEditNgo(false)}
              _hover={{}}
            >
              Add Ngo
            </Button>
          </Link>
          <Input
            size='md'
            borderRadius='0.25rem'
            placeholder='Search by Name / Email'
          />
        </HStack>

        <TableContainer w='100%'>
          <Table w='100%' variant='striped'>
            <Thead>
              <Tr>
                {tableHeadings.map(th => (
                  <ThN>{th}</ThN>
                ))}
              </Tr>
            </Thead>

            <Tbody>
              {ngoData.map((data, index) => (
                <Tr>
                  <Td fontSize='16px' p='0px'>
                    <Box
                      w='100%'
                      h='60px'
                      display='flex'
                      px='10px'
                      gap=''
                      justifyContent='center'
                      alignItems='center'
                      borderLeft='1px solid #d8d6d4'
                      borderBottom='1px solid #d8d6d4'
                    >
                      {index + 1}
                    </Box>
                  </Td>
                  <Td fontSize='16px' p='0px'>
                    <Box
                      w='100%'
                      h='60px'
                      display='flex'
                      px=''
                      gap=''
                      justifyContent='center'
                      alignItems='center'
                      borderLeft='1px solid #d8d6d4'
                      borderBottom='1px solid #d8d6d4'
                    >
                      {data.name}
                    </Box>
                  </Td>
                  <Td fontSize='16px' p='0px'>
                    <Box
                      w='100%'
                      h='60px'
                      display='flex'
                      px=''
                      gap=''
                      justifyContent='center'
                      alignItems='center'
                      borderLeft='1px solid #d8d6d4'
                      borderBottom='1px solid #d8d6d4'
                    >
                      {data.darpanId}
                    </Box>
                  </Td>
                  <Td fontSize='16px' p='0px'>
                    <Box
                      w='100%'
                      h='60px'
                      display='flex'
                      px=''
                      gap=''
                      justifyContent='center'
                      alignItems='center'
                      borderLeft='1px solid #d8d6d4'
                      borderBottom='1px solid #d8d6d4'
                    >
                      <Image
                        w={{ base: '100px', md: '150px' }}
                        h='50px'
                        objectFit={'cover'}
                        src={
                          `/api/image/getImage/` +
                          `${data.id}` +
                          '/' +
                          data.logo
                        }
                        alt='Logo'
                      />
                    </Box>
                  </Td>
                  <Td fontSize='16px' p='0px'>
                    <Box
                      w='100%'
                      h='60px'
                      display='flex'
                      px='5px'
                      gap=''
                      justifyContent='center'
                      alignItems='center'
                      borderLeft='1px solid #d8d6d4'
                      borderBottom='1px solid #d8d6d4'
                    >
                      <Link as={NavLink} to='/ngo/addngo' _hover={{}}>
                        <Button
                          size='sm'
                          bg='#f59120'
                          color='white'
                          _hover={{ bg: '#a85b1b ' }}
                          borderRadius='3px'
                          onClick={() => {
                            setEditNgo(true);
                            localStorage.setItem('editNgo', true);
                            localStorage.setItem('ngoId', data.id);
                            setViewNgoId(data.id);
                          }}
                        >
                          View
                        </Button>
                      </Link>
                    </Box>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
    </Box>
  );
}

const ThN = ({ children }) => {
  return (
    <Th border='1px solid #d8d6d4' color='black'>
      <Text textAlign='center' fontSize='16px'>
        {children}
      </Text>
    </Th>
  );
};
