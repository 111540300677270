import React, { useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Text, Link, theme, IconButton } from '@chakra-ui/react';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { AiOutlineMenu } from 'react-icons/ai';
import { FaSignOutAlt } from 'react-icons/fa';
import { GlobalContext } from '../Context/GlobalContext';

export default function Topbar() {
  const style = {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
    paddingBottom: '3px',
    width: '46px',
    height: '40px',
    background: 'transparent',
  };

  const { expand, setExpand } = useContext(GlobalContext);

  const navigate = useNavigate();
  const handleSignOut = async () => {
    localStorage.clear();
    try {
      await axios.post('/auth/logout');
      navigate('/');
      localStorage.clear();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box
      w='100%'
      // flexGrow={1}
      px='8px'
      py='5px'
      display='flex'
      alignItems='center'
      justifyContent='end'
      borderBottom='1px solid #d8d6d4'
      bg='#eee7da'
      position='sticky'
      top='0'
      zIndex='1'
    >
      {/* <IconButton
        fontSize='20px'
        color='#0009'
        _hover={{ color: 'black' }}
        style={style}
        onClick={() => {
          expand ? setExpand(false) : setExpand(true);
        }}
        visibility={{ base: 'hidden', sm: 'visible' }}
      >
        <AiOutlineMenu />
      </IconButton> */}
      {/* <Box display='flex' alignItems='center'> */}
      {/* <ColorModeSwitcher /> */}
      {/* <Link as={NavLink} to='/' _hover={{}}> */}
      <IconButton
        fontSize='17px'
        color='#0009'
        _hover={{ color: 'black' }}
        style={style}
        onClick={handleSignOut}
      >
        <FaSignOutAlt />
      </IconButton>
      {/* </Link> */}
      {/* </Box> */}
    </Box>
  );
}
