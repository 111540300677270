import React, { useState, createContext } from 'react';
import dayjs from 'dayjs';

export const GlobalContext = createContext();

export const GlobalProvider = props => {
  const [expand, setExpand] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [editCase, setEditCase] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [editNgo, setEditNgo] = useState(false);
  const [editReporter, setEditReporter] = useState(false);
  const [editVet, setEditVet] = useState(false);
  const [editSponsor, setEditSponsor] = useState(false);
  const [caseID, setCaseID] = useState('');
  const [userId, setUserId] = useState('');
  const [viewUserId, setViewUserId] = useState('');
  const [viewNgoId, setViewNgoId] = useState('');
  const [dashboardTab, setDashboardTab] = useState('All');
  const [mortality, setMortality] = useState('Healthy');
  const [type, setType] = useState('Choose');
  const [status, setStatus] = useState('Reported');
  const [causeOfFailure, setCauseOfFailure] = useState('');
  const [caseDetails, setCaseDetails] = useState({
    type,
    status,
    mortality,
    causeOfFailure,
  });
  const [reporterName, setReporterName] = useState('');
  const [reporterContact, setReporterContact] = useState('');
  const [reporterAltContact, setReporterAltContact] = useState('');
  const [reporterEmail, setReporterEmail] = useState('');
  const [reporterVerification, setReporterVerification] = useState([]);
  const [location, setLocation] = useState('');
  const [landmark, setLandmark] = useState('');
  const [pincode, setPincode] = useState('');
  const [reportedDate, setReportedDate] = useState(
    dayjs().format('YYYY-MM-DD')
  );
  const [reportedTime, setReportedTime] = useState(dayjs().format('hh:mm'));
  const [pickupDate, setPickupDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [pickupTime, setPickupTime] = useState(dayjs().format('hh:mm'));
  const [reporterLastMod, setReporterLastMod] = useState(
    localStorage.getItem('role')
  );
  const [consentFormImage, setConsentFormImage] = useState([]);
  const [verificationIdFrontImage, setVerificationIdFrontImage] = useState('');
  const [verificationIdBackImage, setVerificationIdBackImage] = useState('');
  const [reportingDetails, setReportingDetails] = useState({
    reporterName: reporterName,
    reporterContact: reporterContact,
    reporterAltContact: reporterAltContact,
    reporterEmail: reporterEmail,
    location: location,
    pincode: pincode,
    landmark: landmark,
    reportedDate: reportedDate,
    reportedTime: reportedTime,
    pickupDate: pickupDate,
    pickupTime: pickupTime,
    lastModifiedBy: reporterLastMod,
    consentFormImage: consentFormImage,
    verificationIdBackImage: verificationIdBackImage,
    verificationIdFrontImage: verificationIdFrontImage,
  });
  const [animalId, setAnimalId] = useState('');
  const [animalSpecies, setAnimalSpecies] = useState('Dog');
  const [animalBreed, setAnimalBreed] = useState('Indie');
  const [animalAge, setAnimalAge] = useState('1-5 Yrs');
  const [animalTemperament, setAnimalTemperament] = useState('Scared');
  const [animalGender, setAnimalGender] = useState('Female');
  const [animalPregnant, setAnimalPregnant] = useState('No');
  const [animalMarking, setAnimalMarking] = useState('');
  const [animalColor, setAnimalColor] = useState('');
  const [animalCatchable, setAnimalCatchable] = useState('Yes');
  const [animalWeight, setAnimalWeight] = useState('');
  const [admissionReason, setAdmissionReason] = useState('');
  const [animalPictures, setAnimalPictures] = useState([]);
  const [animalLastMod, setAnimalLastMod] = useState(
    localStorage.getItem('role')
  );
  const [animalDetails, setAnimalDetails] = useState({
    uniqueID: animalId,
    animalSpecies: animalSpecies,
    animalBreed: animalBreed,
    animalAge: animalAge,
    animalTemperament: animalTemperament,
    animalGender: animalGender,
    animalPregnant: animalPregnant,
    animalMarking: animalMarking,
    animalColor: animalColor,
    animalCatchable: animalCatchable,
    animalWeight: animalWeight,
    admissionReason: admissionReason,
    animalPictures: animalPictures,
    lastModifiedBy: animalLastMod,
  });
  const [medicalHistory, setMedicalHistory] = useState('');
  const [vaccinationStatus, setVaccinationStatus] = useState('Already Done');
  const [dewormed, setDewormed] = useState('Already Done');
  const [fitForSurgery, setFitForSurgery] = useState('Yes');
  const [otherDetails, setOtherDetails] = useState('');
  const [admissionDate, setAdmissionDate] = useState(
    dayjs().format('YYYY-MM-DD')
  );
  const [feedingRecordImage, setFeedingRecordImage] = useState([]);
  const [bloodReportImage, setBloodReportImage] = useState([]);
  const [medicalLastMod, setMedicalLastMod] = useState(
    localStorage.getItem('role')
  );
  const [medicalDetails, setMedicalDetails] = useState({
    medicalHistory: medicalHistory,
    vaccinationStatus: vaccinationStatus,
    dewormed: dewormed,
    fitForSurgery: fitForSurgery,
    otherDetails: otherDetails,
    admissionDate: admissionDate,
    feedingRecordImage: feedingRecordImage,
    bloodReportImage: bloodReportImage,
    lastModifiedBy: medicalLastMod,
  });
  const [vetName, setVetName] = useState('');
  const [operationDate, setOperationDate] = useState(
    dayjs().format('YYYY-MM-DD')
  );
  const [operationStartTime, setOperationStartTime] = useState(
    dayjs().format('hh:mm')
  );
  const [operationEndTime, setOperationEndTime] = useState(
    dayjs().format('hh:mm')
  );
  const [operationOutcome, setOperationOutcome] = useState('Success');
  const [medicalPrescriptionImage, setMedicalPrescriptionImage] = useState([]);
  const [treatmentRecordImage, setTreatmentRecordImage] = useState([]);
  const [organImage, setOrganImage] = useState([]);
  const [opLastMod, setOpLastMod] = useState(localStorage.getItem('role'));
  const [opDetails, setOpDetails] = useState({
    vetName: vetName,
    operationDate: operationDate,
    operationStartTime: operationStartTime,
    operationEndTime: operationEndTime,
    operationOutcome: operationOutcome,
    medicalPrescriptionImage: medicalPrescriptionImage,
    treatmentRecordImage: treatmentRecordImage,
    organImage: organImage,
    lastModifiedBy: opLastMod,
  });

  const [popComment, setPopComment] = useState('');
  const [popFacility, setPopFacility] = useState('In Shelter');
  const [popExpectedDays, setPopExpectedDays] = useState('');
  const [popStartDate, setPopStartDate] = useState(
    dayjs().format('YYYY-MM-DD')
  );
  const [popEndDate, setPopEndDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [releaseDate, setReleaseDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [comments, setComments] = useState('');
  const [popPictures, setPopPictures] = useState([]);
  const [releasePictures, setReleasePictures] = useState([]);
  const [euthanized, setEuthanized] = useState('No');
  const [popLocation, setPopLocation] = useState('');
  const [popLastMod, setPopLastMod] = useState(localStorage.getItem('role'));
  const [popDetails, setPopDetails] = useState({
    popComment: popComment,
    popFacility: popFacility,
    popExpectedDays: popExpectedDays,
    popStartDate: popStartDate,
    popEndDate: popEndDate,
    releaseDate: releaseDate,
    comments: comments,
    popPictures: popPictures,
    releasePictures: releasePictures,
    euthanized: euthanized,
    popLocation: popLocation,
    lastModifiedBy: popLastMod,
  });
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [contact, setContact] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [verificationId, setVerificationId] = useState([]);
  const [role, setRole] = useState('');
  const [userDetails, setUserDetails] = useState({
    name: name,
    email: email,
    username: username,
    password: password,
    contact: contact,
    profileImage: profileImage,
    verificationId: verificationId,
    role: role,
  });
  const [ngoName, setNgoName] = useState('');
  const [darpanId, setDarpanId] = useState('');
  const [registrationCertificate, setRegistrationCertificate] = useState([]);
  const [panCard, setPanCard] = useState([]);
  const [logo, setLogo] = useState('');
  const [description, setDescription] = useState('');
  const [missionStatement, setMissionStatement] = useState('');
  const [facilityPictures, setFacilityPictures] = useState([]);
  const [helplineNumber1, setHelplineNumber1] = useState('');
  const [helplineNumber2, setHelplineNumber2] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [instagramId, setInstagramId] = useState('');
  const [twitterId, setTwitterId] = useState('');
  const [ngoEmail, setNgoEmail] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [ngoDetails, setNgoDetails] = useState({
    name: ngoName,
    darpanId: darpanId,
    regCert: registrationCertificate,
    panCard: panCard,
    logo: logo,
    desc: description,
    mission: missionStatement,
    facility: facilityPictures,
    helpline: helplineNumber1,
    altHelpline: helplineNumber2,
    facebook: facebookUrl,
    linkedin: linkedinUrl,
    instagram: instagramId,
    twitter: twitterId,
    email: ngoEmail,
    website: websiteUrl,
  });
  // const [vetName, setVetName] = useState('');
  const [vetRegId, setVetRegId] = useState('');
  const [certification, setCertification] = useState('');
  const [vetVerificationId, setVetVerificationId] = useState([]);
  const [vetDetails, setVetDetails] = useState({
    name: vetName,
    regId: vetRegId,
    vetCertification: certification,
    verificationId: vetVerificationId,
  });
  const [sponsorName, setSponsorName] = useState('');
  const [sponsorType, setSponsorType] = useState('');
  const [sponsorAmt, setSponsorAmt] = useState('');
  const [sponsorLogo, setSponsorLogo] = useState('');
  const [sponsorStartDate, setSponsorStartDate] = useState(
    dayjs().format('YYYY-MM-DD')
  );
  const [sponsorEndDate, setSponsorEndDate] = useState(
    dayjs().format('YYYY-MM-DD')
  );
  const [sponsorDetails, setSponsorDetails] = useState({
    name: sponsorName,
    type: sponsorType,
    amount: sponsorAmt,
    logo: sponsorLogo,
    startDate: sponsorStartDate,
    endDate: sponsorEndDate,
  });

  return (
    <GlobalContext.Provider
      value={{
        //general
        tabIndex,
        setTabIndex,
        editCase,
        setEditCase,
        editUser,
        setEditUser,
        editNgo,
        setEditNgo,
        editReporter,
        setEditReporter,
        editVet,
        setEditVet,
        editSponsor,
        setEditSponsor,
        caseID,
        setCaseID,
        userId,
        setUserId,
        viewUserId,
        setViewUserId,
        viewNgoId,
        setViewNgoId,
        expand,
        setExpand,
        dashboardTab,
        setDashboardTab,
        //case
        mortality,
        setMortality,
        type,
        setType,
        status,
        setStatus,
        causeOfFailure,
        setCauseOfFailure,
        caseDetails,
        setCaseDetails,
        //reporter
        reporterName,
        setReporterName,
        reporterContact,
        setReporterContact,
        reporterAltContact,
        setReporterAltContact,
        reporterVerification,
        setReporterVerification,
        reporterEmail,
        setReporterEmail,
        location,
        setLocation,
        landmark,
        setLandmark,
        pincode,
        setPincode,
        reportedDate,
        setReportedDate,
        reportedTime,
        setReportedTime,
        pickupDate,
        setPickupDate,
        pickupTime,
        setPickupTime,
        reporterLastMod,
        setReporterLastMod,
        consentFormImage,
        setConsentFormImage,
        verificationIdFrontImage,
        setVerificationIdFrontImage,
        verificationIdBackImage,
        setVerificationIdBackImage,
        reportingDetails,
        setReportingDetails,
        //animal
        animalId,
        setAnimalId,
        animalSpecies,
        setAnimalSpecies,
        animalBreed,
        setAnimalBreed,
        animalAge,
        setAnimalAge,
        animalTemperament,
        setAnimalTemperament,
        animalGender,
        setAnimalGender,
        animalPregnant,
        setAnimalPregnant,
        animalMarking,
        setAnimalMarking,
        animalColor,
        setAnimalColor,
        animalCatchable,
        setAnimalCatchable,
        animalWeight,
        setAnimalWeight,
        admissionReason,
        setAdmissionReason,
        animalPictures,
        setAnimalPictures,
        animalLastMod,
        setAnimalLastMod,
        animalDetails,
        setAnimalDetails,
        //medical
        medicalHistory,
        setMedicalHistory,
        vaccinationStatus,
        setVaccinationStatus,
        dewormed,
        setDewormed,
        fitForSurgery,
        setFitForSurgery,
        otherDetails,
        setOtherDetails,
        admissionDate,
        setAdmissionDate,
        feedingRecordImage,
        setFeedingRecordImage,
        bloodReportImage,
        setBloodReportImage,
        medicalLastMod,
        setMedicalLastMod,
        medicalDetails,
        setMedicalDetails,
        //operation
        vetName,
        setVetName,
        operationDate,
        setOperationDate,
        operationStartTime,
        setOperationStartTime,
        operationEndTime,
        setOperationEndTime,
        operationOutcome,
        setOperationOutcome,
        medicalPrescriptionImage,
        setMedicalPrescriptionImage,
        treatmentRecordImage,
        setTreatmentRecordImage,
        organImage,
        setOrganImage,
        opLastMod,
        setOpLastMod,
        opDetails,
        setOpDetails,
        //post Operation
        popComment,
        setPopComment,
        popFacility,
        setPopFacility,
        popExpectedDays,
        setPopExpectedDays,
        popStartDate,
        setPopStartDate,
        popEndDate,
        setPopEndDate,
        releaseDate,
        setReleaseDate,
        comments,
        setComments,
        popPictures,
        setPopPictures,
        releasePictures,
        setReleasePictures,
        euthanized,
        setEuthanized,
        popLocation,
        setPopLocation,
        popDetails,
        setPopDetails,
        popLastMod,
        setPopLastMod,
        name,
        setName,
        email,
        setEmail,
        username,
        setUsername,
        password,
        setPassword,
        contact,
        setContact,
        profileImage,
        setProfileImage,
        verificationId,
        setVerificationId,
        role,
        setRole,
        userDetails,
        setUserDetails,
        ngoName,
        setNgoName,
        darpanId,
        setDarpanId,
        registrationCertificate,
        setRegistrationCertificate,
        panCard,
        setPanCard,
        logo,
        setLogo,
        description,
        setDescription,
        missionStatement,
        setMissionStatement,
        facilityPictures,
        setFacilityPictures,
        helplineNumber1,
        setHelplineNumber1,
        helplineNumber2,
        setHelplineNumber2,
        facebookUrl,
        setFacebookUrl,
        linkedinUrl,
        setLinkedinUrl,
        instagramId,
        setInstagramId,
        twitterId,
        setTwitterId,
        ngoEmail,
        setNgoEmail,
        websiteUrl,
        setWebsiteUrl,
        ngoDetails,
        setNgoDetails,
        //vet
        vetRegId,
        setVetRegId,
        certification,
        setCertification,
        vetVerificationId,
        setVetVerificationId,
        vetDetails,
        setVetDetails,
        //sponsor
        sponsorName,
        setSponsorName,
        sponsorType,
        setSponsorType,
        sponsorAmt,
        setSponsorAmt,
        sponsorLogo,
        setSponsorLogo,
        sponsorStartDate,
        setSponsorStartDate,
        sponsorEndDate,
        setSponsorEndDate,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
