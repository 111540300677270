import { React, useState, useEffect, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';
import {
  Box,
  Text,
  Input,
  Link,
  Stack,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Button,
  Heading,
  useToast,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';
import { InputMenu, InputNormal, InputPhotos } from './Inputs';
import { GlobalContext } from '../Context/GlobalContext';

export default function AnimalDetails() {
  const ageOpts = ['0-1 Yrs', '1-5 Yrs', '5-10 Yrs', '10+ Yrs'];
  const speciesOpts = ['Dog', 'Cat', 'Bird', 'Other'];
  const tempOpts = ['Friendly', 'Aggressive', 'Scared', 'Other'];
  const genderOpts = ['Male', 'Female', 'Other'];
  const pregOpts = ['Yes', 'No', 'Not Sure'];
  const breedOpts = ['Indie', 'Pet', 'Other'];
  const catchOpts = ['Yes', 'No'];

  const {
    caseID,
    tabIndex,
    setTabIndex,
    editCase,
    animalId,
    setAnimalId,
    animalSpecies,
    setAnimalSpecies,
    animalBreed,
    setAnimalBreed,
    animalAge,
    setAnimalAge,
    animalTemperament,
    setAnimalTemperament,
    animalGender,
    setAnimalGender,
    animalPregnant,
    setAnimalPregnant,
    animalMarking,
    setAnimalMarking,
    animalColor,
    setAnimalColor,
    animalCatchable,
    setAnimalCatchable,
    animalWeight,
    setAnimalWeight,
    admissionReason,
    setAdmissionReason,
    animalPictures,
    setAnimalPictures,
    animalLastMod,
    setAnimalLastMod,
    animalDetails,
    setAnimalDetails,
  } = useContext(GlobalContext);

  const toast = useToast();
  const AddAnimalDetails = async () => {
    console.log('Adding Animal Details');
    console.log({
      animalSpecies: animalSpecies,
      animalBreed: animalBreed,
      animalAge: animalAge,
      animalTemperament: animalTemperament,
      animalGender: animalGender,
      animalPregnant: animalPregnant,
      animalMarking: animalMarking,
      animalColor: animalColor,
      animalCatchable: animalCatchable,
      animalWeight: animalWeight,
      admissionReason: admissionReason,
      animalPictures: animalPictures,
    });
    var data = {
      animalSpecies: animalSpecies,
      animalBreed: animalBreed,
      animalAge: animalAge.slice(0, 3),
      animalTemperament: animalTemperament,
      animalGender: animalGender,
      animalPregnant: animalPregnant,
      animalMarking: animalMarking,
      animalColor: animalColor,
      animalCatchable: animalCatchable,
      animalWeight: animalWeight,
      admissionReason: admissionReason,
      animalPictures: animalPictures,
      caseId: localStorage.getItem('caseId'),
      // caseId: caseID,
    };
    for (let prop in data) {
      if (
        data[`${prop}`] === '' ||
        data[`${prop}`] === null ||
        (Array.isArray(data[`${prop}`]) && data[`${prop}`].length === 0)
      ) {
        delete data[`${prop}`];
        console.log('removing prop : ', prop);
      }
    }
    console.log('edited data : ', data);
    try {
      const response = await axios.post('/case/updateAnimalDetail', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status == 200) {
        console.log(response.data);
        setTabIndex(2);
      }
    } catch (error) {
      console.error(error);
      toast({
        title: 'Error',
        description: error.response.data.message,
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    }
  };

  const navigate = useNavigate();
  const handleSaveExit = () => {
    AddAnimalDetails();
    navigate('/dashboard');
  };

  const handleSaveNext = () => {
    AddAnimalDetails();
  };

  useEffect(() => {
    console.log(animalDetails);
  }, []);

  return (
    <Stack spacing={8} w={'100%'} py={'10px'}>
      <Heading textAlign='start' fontSize={{ base: '2xl', md: '4xl' }}>
        Further Animal Details :
      </Heading>
      <Box w='300px'>
        <Text
          bg='black'
          color='white'
          borderRadius={'0.25rem'}
          fontWeight='bold'
        >
          Animal ID: {animalId}
        </Text>
      </Box>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: '2', md: '10' }}
      >
        <InputMenu
          val={animalSpecies}
          setVal={setAnimalSpecies}
          options={speciesOpts}
        >
          Animal Species
        </InputMenu>
        <InputMenu
          val={animalBreed}
          setVal={setAnimalBreed}
          options={breedOpts}
        >
          Animal Breed
        </InputMenu>
        <InputMenu val={animalAge} setVal={setAnimalAge} options={ageOpts}>
          Animal Age
        </InputMenu>
      </Stack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: '2', md: '10' }}
      >
        <InputMenu
          val={animalTemperament}
          setVal={setAnimalTemperament}
          options={tempOpts}
        >
          Animal Temperament
        </InputMenu>
        <InputMenu
          val={animalGender}
          setVal={setAnimalGender}
          options={genderOpts}
        >
          Animal Gender
        </InputMenu>
        <InputMenu
          val={animalPregnant}
          setVal={setAnimalPregnant}
          options={pregOpts}
        >
          Animal Pregnant
        </InputMenu>
      </Stack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: '2', md: '10' }}
      >
        <InputNormal
          value={animalMarking}
          setVar={setAnimalMarking}
          placeholder={'Animal Marking'}
        >
          Animal Marking
        </InputNormal>
        <InputNormal
          value={animalColor}
          setVar={setAnimalColor}
          placeholder={'Animal Color'}
        >
          Animal Color
        </InputNormal>
        <InputMenu
          val={animalCatchable}
          setVal={setAnimalCatchable}
          options={catchOpts}
        >
          Animal Catchable
        </InputMenu>
      </Stack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: '2', md: '10' }}
      >
        <InputNormal
          value={animalWeight}
          setVar={setAnimalWeight}
          placeholder={'Animal Weight'}
        >
          Animal Weight (kgs)
        </InputNormal>
        <InputNormal
          value={admissionReason}
          setVar={setAdmissionReason}
          placeholder={'Reason for Admission'}
        >
          Reason for Admission
        </InputNormal>
      </Stack>
      <InputPhotos
        value={animalPictures}
        setVar={setAnimalPictures}
        field={'AnimalPictures'}
      >
        Animal Pictures
      </InputPhotos>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        w='100%'
        justifyContent='space-between'
      >
        <HStack justifyContent={{ base: 'space-between' }}>
          <Button
            bg='#17a2b8'
            size={{ base: 'sm', md: 'md' }}
            borderRadius='0.25rem'
            color='white'
            _hover={{ bg: '#268594' }}
            onClick={() => {
              setTabIndex(0);
            }}
          >
            Previous
          </Button>
          <Button
            bg='#17a2b8'
            size={{ base: 'sm', md: 'md' }}
            borderRadius='0.25rem'
            color='white'
            _hover={{ bg: '#268594' }}
          >
            <Link as={NavLink} to='/dashboard' fontSize='15px' _hover={{}}>
              Exit
            </Link>
          </Button>
        </HStack>
        <HStack justifyContent={{ base: 'space-between' }}>
          <Button
            bg='#17a2b8'
            size={{ base: 'sm', md: 'md' }}
            borderRadius='0.25rem'
            color='white'
            _hover={{ bg: '#268594' }}
            onClick={handleSaveExit}
          >
            Save & Exit
          </Button>
          <Button
            bg='#17a2b8'
            size={{ base: 'sm', md: 'md' }}
            borderRadius='0.25rem'
            color='white'
            _hover={{ bg: '#268594' }}
            onClick={handleSaveNext}
          >
            Save & Next
          </Button>
        </HStack>
      </Stack>
    </Stack>
  );
}
