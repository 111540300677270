import { React, useState, useEffect, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Text,
  Input,
  Link,
  VStack,
  Stack,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Button,
  Heading,
  useToast,
} from '@chakra-ui/react';
import {
  InputNormal,
  InputDate,
  InputTime,
  InputPhoto,
  InputPhotos,
} from './Inputs';
import { GlobalContext } from '../Context/GlobalContext';

export default function ReportingDetails() {
  const {
    tabIndex,
    setTabIndex,
    editCase,
    userId,
    caseID,
    reporterName,
    setReporterName,
    reporterContact,
    setReporterContact,
    reporterAltContact,
    setReporterAltContact,
    reporterEmail,
    setReporterEmail,
    location,
    setLocation,
    landmark,
    setLandmark,
    pincode,
    setPincode,
    reportedDate,
    setReportedDate,
    reportedTime,
    setReportedTime,
    pickupDate,
    setPickupDate,
    pickupTime,
    setPickupTime,
    reporterLastMod,
    setReporterLastMod,
    consentFormImage,
    setConsentFormImage,
    verificationIdFrontImage,
    setVerificationIdFrontImage,
    verificationIdBackImage,
    setVerificationIdBackImage,
    reportingDetails,
    setReportingDetails,
  } = useContext(GlobalContext);

  const toast = useToast();

  const AddReportingDetails = async () => {
    console.log('Adding reporting details');
    var data = {
      reporterName: reporterName,
      reporterContact: reporterContact,
      reporterAltContact: reporterAltContact,
      reporterEmail: reporterEmail,
      location: location,
      pincode: pincode,
      landmark: landmark,
      reportedDate: reportedDate,
      reportedTime: reportedTime,
      pickupDate: pickupDate,
      pickupTime: pickupTime,
      consentFormImage: consentFormImage,
      verificationIdBackImage: verificationIdBackImage,
      verificationIdFrontImage: verificationIdFrontImage,
      caseId: localStorage.getItem('caseId'),
      // caseId: caseID,
    };
    for (let prop in data) {
      if (
        data[`${prop}`] === '' ||
        data[`${prop}`] === null ||
        (Array.isArray(data[`${prop}`]) && data[`${prop}`].length === 0)
      ) {
        delete data[`${prop}`];
        console.log('removing prop : ', prop);
      }
    }
    console.log('edited data : ', data);
    try {
      const response = await axios.post('/case/updateReportingDetail', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status == 200) {
        console.log(response.data);
        setTabIndex(1);
      }
    } catch (error) {
      console.error(error);
      toast({
        title: 'Error',
        description: error.response.data.message,
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    }
  };

  const navigate = useNavigate();
  const handleSaveExit = () => {
    if (
      reporterName == '' ||
      reporterContact == '' ||
      location == '' ||
      pincode == '' ||
      landmark == '' ||
      reportedDate == '' ||
      reportedTime == ''
    ) {
      toast({
        title: 'Error',
        description: 'Please fill Required Details',
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    } else {
      AddReportingDetails();
      navigate('/dashboard');
    }
  };

  const handleSaveNext = () => {
    if (
      reporterName == '' ||
      reporterContact == '' ||
      location == '' ||
      pincode == '' ||
      landmark == '' ||
      reportedDate == '' ||
      reportedTime == ''
    ) {
      toast({
        title: 'Error',
        description: 'Please fill Required Details',
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    } else {
      // setTabIndex(1);
      AddReportingDetails();
    }
  };

  return (
    <Stack spacing={8} w={'100%'} py={'10px'}>
      <Heading textAlign='start' fontSize={{ base: '2xl', md: '4xl' }}>
        Reporter Details :
      </Heading>

      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: '2', md: '10' }}
      >
        <InputNormal
          value={reporterName}
          setVar={setReporterName}
          placeholder={'Name'}
          required={true}
        >
          Reporter name
        </InputNormal>
        <InputNormal
          value={reporterContact}
          setVar={setReporterContact}
          placeholder={'Phone Number'}
          required={true}
        >
          Phone Number
        </InputNormal>
        <InputNormal
          value={reporterAltContact}
          setVar={setReporterAltContact}
          placeholder={'Alternate Phone Number'}
          required={false}
        >
          Alternate Phone Number
        </InputNormal>
        <InputNormal
          value={reporterEmail}
          setVar={setReporterEmail}
          placeholder={'Email ID'}
          required={false}
        >
          Email ID
        </InputNormal>
      </Stack>

      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: '2', md: '10' }}
      >
        <InputNormal
          value={landmark}
          setVar={setLandmark}
          placeholder={'Near to xyz place'}
          required={true}
        >
          Landmark
        </InputNormal>
        <InputNormal
          value={pincode}
          setVar={setPincode}
          placeholder={'Pincode'}
          required={true}
        >
          Pincode
        </InputNormal>
        <InputNormal
          value={location}
          setVar={setLocation}
          placeholder={'Location'}
          required={true}
        >
          Location
        </InputNormal>
      </Stack>

      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: '2', md: '10' }}
      >
        <InputDate
          value={reportedDate}
          placeholder={'Reported Date'}
          setVar={setReportedDate}
        >
          Reported Date
        </InputDate>
        <InputTime
          value={reportedTime}
          placeholder={'Reported Time'}
          setVar={setReportedTime}
        >
          Reported Time
        </InputTime>
        <InputDate
          value={pickupDate}
          placeholder={'Pickup Date'}
          setVar={setPickupDate}
        >
          Pickup Date
        </InputDate>
        <InputTime
          value={pickupTime}
          placeholder={'Pickup Time'}
          setVar={setPickupTime}
        >
          Pickup Time
        </InputTime>
      </Stack>
      <Heading textAlign='start' fontSize={'xl'}>
        Reporter Photo ID -
      </Heading>
      <Stack direction={{ base: 'column', md: 'row' }}>
        <InputPhoto
          value={verificationIdFrontImage}
          setVar={setVerificationIdFrontImage}
          field={'verificationIdFrontImage'}
        >
          Front Photo
        </InputPhoto>
        <InputPhoto
          value={verificationIdBackImage}
          setVar={setVerificationIdBackImage}
          field={'verificationIdBackImage'}
        >
          Back Photo
        </InputPhoto>
      </Stack>
      <VStack align='start' spacing={{ base: '2', md: '10' }}>
        <InputPhotos
          value={consentFormImage}
          setVar={setConsentFormImage}
          field={'consentFormImage'}
        >
          Consent Form
        </InputPhotos>
      </VStack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        w='100%'
        justifyContent='space-between'
      >
        <HStack justifyContent={{ base: 'space-between' }}>
          <Button
            bg='#17a2b8'
            size={{ base: 'sm', md: 'md' }}
            borderRadius='0.25rem'
            color='white'
            _hover={{ bg: '#268594' }}
            onClick={() => {
              setTabIndex(0);
            }}
          >
            Previous
          </Button>
          <Button
            bg='#17a2b8'
            size={{ base: 'sm', md: 'md' }}
            borderRadius='0.25rem'
            color='white'
            _hover={{ bg: '#268594' }}
          >
            <Link as={NavLink} to='/dashboard' fontSize='15px' _hover={{}}>
              Exit
            </Link>
          </Button>
        </HStack>
        <HStack justifyContent={{ base: 'space-between' }}>
          <Button
            bg='#17a2b8'
            size={{ base: 'sm', md: 'md' }}
            borderRadius='0.25rem'
            color='white'
            _hover={{ bg: '#268594' }}
            onClick={handleSaveExit}
          >
            Save & Exit
          </Button>
          <Button
            bg='#17a2b8'
            size={{ base: 'sm', md: 'md' }}
            borderRadius='0.25rem'
            color='white'
            _hover={{ bg: '#268594' }}
            onClick={handleSaveNext}
          >
            Save & Next
          </Button>
        </HStack>
      </Stack>
    </Stack>
  );
}
